<script>
export default {
  name: 'CouncilTaxBandInfo',
};
</script>

<template>
  <div class="text-center">
    <i class="icon-info3 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
    <h3>HELP WITH FINDING YOUR COUNCIL TAX BAND</h3>
    <p>You can search for your address on the government website and ascertain your council tax band from this:</p>
    <p>
      <a href="https://www.gov.uk/council-tax-bands" target="_blank">https://www.gov.uk/council-tax-bands</a>
    </p>
  </div>
</template>
