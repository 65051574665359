import * as QueryString from 'querystring';

export default class TitleRequest {
  BuildingName;
  BuildingNumber;
  CityName;
  CustomerReference;
  ExternalReference;
  PostCode;
  StreetName;

  constructor(id, name, number, street, city, postCode) {
    this.BuildingName = name;
    this.BuildingNumber = number;
    this.StreetName = street;
    this.CityName = city;
    this.PostCode = postCode;
    this.ExternalReference = id.substr(0, 25);
    this.CustomerReference = id.substr(0, 25);
  }

  static fromAddress(id, { premise, thoroughfare, locality, postal_code }) {
    return new TitleRequest(id, '', premise, thoroughfare, locality, postal_code);
  }

  static fromSearchResult(id, { BuildingName, BuildingNumber, City, PostalCode, Street, SubBuilding }) {
    return new TitleRequest(id, BuildingName, BuildingNumber || SubBuilding, Street, City, PostalCode);
  }

  toQueryString() {
    return QueryString.stringify(this);
  }
}
