import axios from 'axios';

export default ({
  id,
  clientIds,
  authorityPurchase,
  authorityPurchaseDetails,
  propertyType,
  propertyTypeOther,
  purchaseType,
  councilTaxBand,
  buyerName,
  salePrice,
  fixturePrice,
  agentFee,
  agentFeeType,
  agentFeePaymentPermission,
  relatedCase,
  relatedCases,
  specialPurchase,
  specialPurchaseDetails,
  occupierIds,
  mortgagesOrCharges,
  leaseholdToFreehold,
  titleNumbers,
  desiredCompletionDate,
  exchangeDeadline,
  completionDeadline,
  surplusPaymentMethod,
  postCompletionPhone,
  postCompletionAddress,
  sagepayTransactionId,
  chiefOrGroundRent,
  chiefOrGroundRentAddress,
  chiefOrGroundRentPhone,
  titleDeedDeclared,
  titleDeedAlternatives,
  passStatementsToBroker,
  bankName,
  bankAccountName,
  bankAccountNumber,
  bankBacsOrChaps,
  bankSortCode,
  additionalInfo,
  soldByEstateAgent,
  soldByCompany,
  soldByCompanyDetails,
  buildingId,
}) => {
  const preparedData = {
    client_ids: clientIds,
    authority_purchase: authorityPurchase,
    authority_purchase_details: authorityPurchaseDetails,
    property_type: propertyType,
    property_type_other: propertyTypeOther,
    purchase_type: purchaseType,
    council_tax_band: councilTaxBand,
    buyer_name: buyerName,
    sale_price: salePrice,
    fixture_price: fixturePrice,
    agent_fee: agentFee,
    agent_fee_type: agentFeeType,
    agent_fee_payment_permission: agentFeePaymentPermission,
    related_case: relatedCase,
    related_cases: relatedCases,
    special_purchase: specialPurchase,
    special_purchase_details: specialPurchaseDetails,
    occupier_ids: occupierIds,
    mortgages_or_charges: mortgagesOrCharges,
    leasehold_to_freehold: leaseholdToFreehold,
    title_numbers: titleNumbers,
    desired_completion_date: desiredCompletionDate,
    exchange_deadline: exchangeDeadline,
    completion_deadline: completionDeadline,
    surplus_payment_method: surplusPaymentMethod,
    post_completion_phone: postCompletionPhone,
    post_completion_address: postCompletionAddress,
    sagepay_transaction_id: sagepayTransactionId,
    chief_or_ground_rent: chiefOrGroundRent,
    chief_or_ground_rent_address: chiefOrGroundRentAddress,
    chief_or_ground_rent_phone: chiefOrGroundRentPhone,
    title_deed_declared: titleDeedDeclared,
    title_deed_alternatives: titleDeedAlternatives,
    pass_statements_to_broker: passStatementsToBroker,
    additional_info: additionalInfo,
    sold_by_estate_agent: soldByEstateAgent,
    sold_by_company: soldByCompany,
    sold_by_company_details: soldByCompanyDetails,
    building_id: buildingId,
  };

  // Overwrite values for not required fields by passing empty string
  if (typeof chiefOrGroundRent !== 'undefined' && chiefOrGroundRent !== 'YNNK_YES') {
    preparedData.chief_or_ground_rent_address = '';
    preparedData.chief_or_ground_rent_phone = '';
  }

  if (typeof specialPurchase !== 'undefined' && specialPurchase !== true) {
    preparedData.special_purchase_details = '';
  }

  if (typeof titleDeedDeclared !== 'undefined' && titleDeedDeclared !== false) {
    preparedData.title_deed_alternatives = '';

    preparedData.sold_by_company = soldByCompany;

    if (soldByCompany) {
      preparedData.sold_by_company_details = {
        company_name: soldByCompanyDetails.company_name,
        company_number: soldByCompanyDetails.company_number,
        director_or_authorised_person: soldByCompanyDetails.director_or_authorised_person,
        country_of_incorporation: soldByCompanyDetails.country_of_incorporation,
      };
    }
  }

  if (typeof propertyType !== 'undefined' && propertyType !== 'other') {
    preparedData.property_type_other = '';
  }

  if (bankName || bankAccountName || bankAccountNumber || bankSortCode || bankBacsOrChaps) {
    if (bankSortCode) {
      bankSortCode = String(bankSortCode).replace(/-/g, '');
    }

    preparedData.bank_details = {
      bank_name: bankName,
      account_name: bankAccountName,
      account_number: bankAccountNumber,
      sort_code: bankSortCode,
      bacs_or_chaps: bankBacsOrChaps,
    };
  }

  // Remove all undefined and null from payload
  const saleData = Object.entries(preparedData).reduce(
    (a, [k, v]) => (v === undefined || v === null ? a : ((a[k] = v), a)),
    {},
  );

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/sales/${id}`, saleData);
};
