<script>
import { mapMutations, mapState } from 'vuex';
import * as saleMutations from '@/store/sale/mutations';
import patchSale from '@/api/sales/patchSale';
import LenderOptions from '@/mixins/LenderOptions';

const bacsOrChapsOptions = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'bacs', text: 'BACS' },
  { value: 'chaps', text: 'CHAPS' },
];

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrUnsure = {
  YNU_YES: 'Yes',
  YNU_NO: 'No',
  YNU_UNSURE: 'Unsure',
};

export default {
  name: 'OtherQuestionnnaire',
  mixins: [LenderOptions],
  components: {},
  data() {
    return {
      bacsOrChapsOptions,
      yesOrNo,
      yesOrNoOrUnsure,
    };
  },
  computed: {
    ...mapState({
      s_sortReferId: (state) => state.sortReferId,
      s_saleId: (state) => state.sale.saleId,
      s_bankName: (state) => state.sale.bankName,
      s_bankAccountName: (state) => state.sale.bankAccountName,
      s_bankSortCode: (state) => state.sale.bankSortCode,
      s_bankAccountNumber: (state) => state.sale.bankAccountNumber,
      s_bankBacsOrChaps: (state) => state.sale.bankBacsOrChaps,
      s_additionalInfo: (state) => state.sale.additionalInfo,
    }),
    bankName: {
      get() {
        return this.s_bankName;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_NAME}`](value);
      },
    },
    accountName: {
      get() {
        return this.s_bankAccountName;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_ACCOUNT_NAME}`](value);
      },
    },
    sortCode: {
      get() {
        return this.s_bankSortCode;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_SORT_CODE}`](value);
      },
    },
    accountNumber: {
      get() {
        return this.s_bankAccountNumber;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_ACCOUNT_NUMBER}`](value);
      },
    },
    bacsOrChaps: {
      get() {
        return this.s_bankBacsOrChaps;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_BACS_OR_CHAPS}`](value);
      },
    },
    additionalInformation: {
      get() {
        return this.s_additionalInfo;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_ADDITIONAL_INFO}`](value);
      },
    },
    bankNameOption: {
      get() {
        return this.getLenderNameOptionFromName(this.s_bankName);
      },
    },
    showBankNameFreeTextInput: {
      get() {
        return this.bankNameOption && this.bankNameOption.id === 0;
      },
    },
  },
  methods: {
    ...mapMutations([
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_NAME}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_ACCOUNT_NAME}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_SORT_CODE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_ACCOUNT_NUMBER}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BANK_BACS_OR_CHAPS}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_ADDITIONAL_INFO}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      const preparedData = {
        id: this.s_saleId,
        bankName: this.s_bankName,
        bankAccountName: this.s_bankAccountName,
        bankSortCode: this.s_bankSortCode,
        bankAccountNumber: this.s_bankAccountNumber,
        bankBacsOrChaps: this.s_bankBacsOrChaps,
        additionalInfo: this.s_additionalInfo,
      };

      await patchSale(preparedData);
      this.saving = false;
      return true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    setBankName(value) {
      this.bankName = this.getLenderNameFromOption(value);
    },
  },
};
</script>

<template>
  <ValidationObserver ref="observer" v-slot="{ errors }">
    <div>
      <h2>Additional Information</h2>

      <div class="row pb-2">
        <div class="col-md-12">
          <div class="section-title">
            <h3>Bank Details</h3>
          </div>
          <div class="section-note">
            <p>
              We ask for your bank details so that we can pay any money that may be owing to you on the completion of
              the sale into the specifed account. Please be sure that you enter these correctly.
            </p>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Bank: </label>
          </h5>
        </div>
        <div class="col-md-9">
          <ValidationProvider name="bank name" rules="required" v-slot="validationContext">
            <v-select
              :value="bankNameOption"
              @input="setBankName"
              :options="lenders"
              id="bank-name"
              class="bg-white"
              v-bind:class="{
                'is-valid': !validationContext.errors.length,
                'is-invalid': validationContext.errors.length,
              }"
              :state="getValidationState(validationContext)"
              ><span slot="no-options">{{ noMatchingLenderMessage }}</span></v-select
            >
            <div
              v-if="validationContext.errors"
              v-bind:class="{ 'invalid-feedback d-block': validationContext.errors }"
              data-error-name="bank-name-select-error"
            >
              {{ validationContext.errors[0] }}
            </div>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="showBankNameFreeTextInput">
        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="mandatory"> Bank (Other): </label>
            </h5>
          </div>
          <div class="col-md-9">
            <ValidationProvider name="bank name (other)" rules="required" v-slot="validationContext">
              <b-form-input
                v-model="bankName"
                name="bankName"
                debounce="500"
                class="form-control"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="bank-name-other-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Account Name: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="account name" rules="required|account_name" v-slot="validationContext">
            <b-form-input
              v-model="accountName"
              name="accountName"
              debounce="500"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="account-name-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Sort Code: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="sort code" rules="required|sort_code" v-slot="validationContext">
            <b-form-input
              v-model="sortCode"
              name="sortCode"
              debounce="500"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="sort-code-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Account Number: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="account number" rules="required|account_number" v-slot="validationContext">
            <b-form-input
              v-model="accountNumber"
              name="accountNumber"
              debounce="500"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="account-number-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <div class="section-title">
            <h3>Payment method</h3>
          </div>
          <div class="section-note">
            <p>
              Payments of over &pound;1000 and same day payments, requested by the client, must be made by CHAPS. A
              processing fee of &pound;35 &plus; VAT will be added to your invoice if CHAPS is requested. BACS transfers
              are free of charge.
            </p>
          </div>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-md-9">
          <h5>
            <label class="mandatory"> BACS or CHAPS? </label>
          </h5>
        </div>
        <div class="col-md-3">
          <ValidationProvider name="BACS or CHAPS" :rules="{ required: true }" v-slot="validationContext">
            <b-form-select
              v-model="bacsOrChaps"
              :options="bacsOrChapsOptions"
              id="bacs-or-chaps"
              class="form-control bg-white"
              :state="getValidationState(validationContext)"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="bacs-or-chaps-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <div class="section-title">
            <h3>Additional Information</h3>
          </div>
          <div class="section-note">
            <p>
              Please confirm if there is anything else we should know e.g. have you changed your name. Or anything
              registered against the property that we need to be aware of in order to deal with the sale transaction.
              Letting us know at this early stage will assist us in being able to raise any enquiries we feel necessary
              for the Land Registry to complete the transfer of title.
            </p>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Additional information: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="additional information" :rules="{ required: false }" v-slot="validationContext">
            <b-form-textarea
              v-model="additionalInformation"
              name="additionalInformation"
              rows="4"
              debounce="500"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback data-error-name="additional-information-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss" scoped></style>
