'use strict';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';

/**
 * @example
 *   import snakeToCamelCase from '@/functions/snakeToCamelCase';
 *   snakeToCamelCase({bad_key: 1});   => {badKey: 1}
 *   snakeToCamelCase([{bad_key: 1}]); => [{badKey: 1}]
 */

function snakeToCamelCase(object) {
  let camelCaseObject = cloneDeep(object);

  if (isArray(camelCaseObject)) {
    return map(camelCaseObject, snakeToCamelCase);
  } else {
    camelCaseObject = mapKeys(camelCaseObject, (value, key) => {
      return camelCase(key);
    });

    // Recursively apply throughout object
    return mapValues(camelCaseObject, (value) => {
      if (isPlainObject(value)) {
        return snakeToCamelCase(value);
      } else if (isArray(value)) {
        return map(value, snakeToCamelCase);
      } else {
        return value;
      }
    });
  }
}

export default snakeToCamelCase;
