<script>
import Modal from '@/components/Modal';
import {
  AWAITING_RESPONSE_DOCUMENT_ANALYSIS,
  AWAITING_RESPONSE_HMLR,
  SUBMISSION_NOT_INITIATED,
} from '@/values/cqSubmissionStatuses';

export default {
  name: 'HmlrPending',
  components: { Modal },
  props: ['status', 'timedOut'],
  data() {
    return {
      progressMessages: {
        [AWAITING_RESPONSE_DOCUMENT_ANALYSIS]: 'Analysing title document',
        [AWAITING_RESPONSE_HMLR]: 'Fetching title document from HMLR',
        [SUBMISSION_NOT_INITIATED]: 'Initiating request',
      },
    };
  },
};
</script>

<template>
  <modal :showHeader="false" :showButtons="false">
    <h2 class="text-center mb-3">Please wait</h2>
    <div class="text-center">
      <p>We are contacting HM Land Registry to confirm your name and property ownership details.</p>
      <template v-if="timedOut">
        <p>This process is taking longer than expected.</p>
        <p>
          Please refresh the page or return to this page later via the
          <router-link :to="{ name: 'Dashboard' }">dashboard</router-link>.
        </p>
      </template>
      <template v-else>
        <p>
          This can take a few minutes; you can return to this page via the
          <router-link :to="{ name: 'Dashboard' }">dashboard</router-link>.
        </p>
        <br /><br />
        <p>{{ progressMessages[status] || 'Initiating request' }}</p>
        <i class="icon-spinner2 spinner"></i>
      </template>
    </div>
  </modal>
</template>
