<script>
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';
import { mapMutations, mapState } from 'vuex';
import * as firmMutations from '@/store/firm/mutations';
import * as saleMutations from '@/store/sale/mutations';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchSale from '@/api/sales/patchSale';
import patchFirm from '@/api/firms/patchFirm';
import postFirm from '@/api/firms/postFirm';
import CurrencyInput from '@/components/forms/inputGroups/CurrencyInput';

/**
 * Options for the agent fee payment permission.
 */
const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const feeTypes = [
  { value: null, text: 'Please choose', disabled: true, selected: true },
  { value: 'fee', text: 'Fee' },
  { value: 'percentage', text: 'Percentage' },
];

export default {
  name: 'YourEstateAgent',
  components: {
    AddressLookup,
    SimpleQuestion,
    CurrencyInput,
  },
  data: () => {
    return {
      yesOrNo,
      feeTypes,
    };
  },
  computed: {
    ...mapState({
      s_firmId: (state) => state.sale.estateAgentId,
      s_saleId: (state) => state.sale.saleId,
      s_agentFee: (state) => state.sale.agentFee,
      s_agentFeeType: (state) => state.sale.agentFeeType,
      s_agentFeePaymentPermission: (state) => state.sale.agentFeePaymentPermission,
      s_productCode: (state) => state.sale.productCode,
      s_soldByEstateAgent: (state) => state.sale.soldByEstateAgent,
      s_phone: (state) => state.firm.phone,
      s_firm_name: (state) => state.firm.name,
      s_address: (state) => state.firm.address,
      s_system: (state) => state?.sale?.config?.system,
    }),
    firmName: {
      get() {
        return this.s_firm_name;
      },
      set(value) {
        this[`${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_NAME}`](value);
      },
    },
    phone: {
      get() {
        return this.s_phone;
      },
      set(value) {
        this[`${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_PHONE}`](value);
      },
    },
    address: {
      get() {
        return this.s_address;
      },
      set(value) {
        this[`${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_ADDRESS}`](value);
      },
    },
    agentFeeType: {
      get() {
        return this.s_agentFeeType;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_AGENT_FEE_TYPE}`](value);
      },
    },
    agentFee: {
      get() {
        return this.s_agentFee;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_AGENT_FEE}`](Number(value));
      },
    },
    agentFeePaymentPermission: {
      get() {
        return {
          answer: this.s_agentFeePaymentPermission,
        };
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_AGENT_FEE_PAYMENT_PERMISSION}`](value.answer);
      },
    },
    soldByEstateAgent: {
      get() {
        return {
          answer: this.s_soldByEstateAgent,
        };
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SOLD_BY_ESTATE_AGENT}`](value.answer);
      },
    },
    isAuctionPack() {
      return this.s_productCode === 'AP' || this.s_productCode === 'APK';
    },
    isSortRefer() {
      return this.s_system === 'SortRefer';
    },
  },
  methods: {
    ...mapMutations([
      `${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_NAME}`,
      `${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_PHONE}`,
      `${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_ADDRESS}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_AGENT_FEE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_AGENT_FEE_TYPE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_AGENT_FEE_PAYMENT_PERMISSION}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SOLD_BY_ESTATE_AGENT}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      if (this.s_firmId) {
        await patchFirm({
          id: this.s_firmId,
          name: this.s_firm_name,
          phone: this.s_phone,
          address: this.s_address,
        });
      } else {
        const postFirmResult = await postFirm({
          name: this.s_firm_name,
          phone: this.s_phone,
          address: this.s_address,
        });

        const firmId = postFirmResult.map((item) => item.data.Id);
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_ESTATE_AGENT_ID}`](firmId[0]);
      }

      if (!this.isAuctionPack) {
        await patchSale({
          id: this.s_saleId,
          agentFee: this.s_agentFee,
          agentFeeType: this.s_agentFeeType,
          agentFeePaymentPermission: this.s_agentFeePaymentPermission,
          soldByEstateAgent: this.s_soldByEstateAgent,
        });
      } else {
        await patchSale({
          id: this.s_saleId,
          soldByEstateAgent: this.s_soldByEstateAgent,
        });
      }

      this.saving = false;

      return true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <validation-observer ref="observer">
    <h2>{{ isAuctionPack ? 'Your Agent' : 'Your Estate Agent' }}</h2>

    <div class="row pb-1" v-if="isSortRefer">
      <div class="col-md-12">
        <simple-question
          :dropdownOptions="yesOrNo"
          labelName="soldByEstateAgentLabelName"
          :title="isAuctionPack ? 'Are you using an Agent?' : 'Are you using an Estate Agent?'"
          v-model="soldByEstateAgent"
          id="soldByEstateAgent"
          validationFieldName="using an Estate Agent"
        />
      </div>
    </div>

    <div v-if="soldByEstateAgent.answer || !isSortRefer">
      <div class="row pb-1">
        <div class="col-md-3">
          <h5>
            <label for="estateAgentName" class="mandatory">
              {{ isAuctionPack ? 'Agent Name' : 'Estate Agent Name' }}:
            </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="agent name" rules="required" v-slot="validationContext">
            <b-form-input
              v-model="firmName"
              id="estateAgentName"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="agent-name-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-1">
        <div class="col-md-3">
          <h5>
            <label> {{ isAuctionPack ? 'Agent Address:' : 'Estate Agent Address:' }} </label>
          </h5>
        </div>
      </div>

      <address-lookup v-model="address" id="address" :addResidency="false" :requiresValidation="false" />

      <div v-if="!isAuctionPack">
        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="agentFeeType" class="mandatory"> Estate Agent Fee Type: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="agent fee type" rules="required" v-slot="validationContext">
              <b-form-select
                v-model="agentFeeType"
                :options="feeTypes"
                name="title"
                id="agentFeeType"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
                aria-describedby="agent-fee-type-error"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="agent-fee-type-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-1">
          <div class="col-md-3">
            <h5>
              <label for="agentFee" class="mandatory"> Estate Agent Fee: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <currency-input
              v-model="agentFee"
              validationFieldName="agent fee"
              type="number"
              id="agent-fee"
              :label="this.agentFeeType !== 'fee' ? '%' : '£'"
              :required="true"
            ></currency-input>
          </div>
        </div>

        <div class="row pb-1">
          <div class="col-md-12">
            <simple-question
              :dropdownOptions="yesOrNo"
              labelName="agentFeePaymentPermissionLabelName"
              title="Please confirm we are authorised to pay the above fees to your Estate Agent upon completion?"
              v-model="agentFeePaymentPermission"
              id="agentFeePaymentPermission"
              validationFieldName="agent fee payment permission"
            />
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<style lang="scss" scoped></style>
