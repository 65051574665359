<script>
import DatePicker from '@/components/DatePicker';
import patchSalePersons from '@/api/persons/patchSalePersons';
import { mapMutations } from 'vuex';
import * as personsMutations from '@/store/persons/mutations';
import Loader from '@/components/Loader';
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';

const titles = [
  { value: null, text: 'Please choose', disabled: true },
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Dr',
  'Sir',
  'Other',
];

export default {
  components: {
    DatePicker,
    Loader,
    AddressLookup,
  },
  name: 'QuickEditPersonQuestionnaire',
  data() {
    return {
      noMiddleNameConfirmed: false,
      personData: {},
      editing: false,
      titles,
      showAsteriskForMandatory: false,
      saving: false,
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    personIndex: {
      type: Number,
      required: true,
    },
  },
  beforeMount() {
    this.personData = { ...this.value };
  },
  methods: {
    ...mapMutations([
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_SET}`,
      `${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_DETAILS_UPDATED}`,
    ]),
    /**
     * Generate validation message for each field
     *
     * @param dirty
     * @param validated
     * @param valid
     * @returns {*}
     */
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    /**
     * This method adds a new previous address or appends a old one
     * @returns {Promise<boolean>}
     */
    async triggerUpdate() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.saving = true;
        // Update Vuex store with the latest changes
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_SET}`]({
          index: this.personIndex,
          value: this.personData,
        });

        // force rerender persons
        await this[`${personsMutations.PERSONS_PREFIX}${personsMutations.PERSON_CLIENT_DETAILS_UPDATED}`](true);

        const personValue = [];
        personValue.push(this.personData);
        await patchSalePersons({ persons: personValue });
        this.editing = false;
        this.saving = false;
      } else {
        const element = this.$el.querySelector('[aria-invalid=true]');
        const offset = document.getElementById('top-nav').offsetHeight + 10;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    },
    triggerEdit(value) {
      if (!value) {
        this.personData = { ...this.value };
      }
      this.showAsteriskForMandatory = value;
      this.editing = value;
    },
  },
  computed: {
    middleNameSet: function () {
      if (!this.personData) {
        return false;
      }

      return this.personData?.middleName?.length > 0;
    },
  },
};
</script>

<template>
  <div>
    <loader v-if="this.saving">Saving Person Data...</loader>
    <div v-else>
      <ValidationObserver ref="observer">
        <div v-show="editing" class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="about_you_title" :class="this.showAsteriskForMandatory ? 'mandatory' : ''"> Title: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="title" rules="required" v-slot="validationContext">
              <b-form-select
                v-model="personData.title"
                :options="titles"
                name="title"
                id="about_you_title"
                class="form-control bg-white"
                :state="getValidationState(validationContext)"
                aria-describedby="title-error"
              ></b-form-select>
              <b-form-invalid-feedback data-error-name="title-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="first-name" :class="this.showAsteriskForMandatory ? 'mandatory' : ''"> First Name: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="first name"
              :rules="{ required: true, person_name: 'first name' }"
              v-slot="validationContext"
            >
              <b-form-input
                v-model.trim="personData.firstName"
                id="first-name"
                class="form-control"
                :disabled="!editing"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="first-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="middle-name"> Middle Name(s): </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="middle name" :rules="{ person_name: 'middle name' }" v-slot="validationContext">
              <b-form-input
                v-model.trim="personData.middleName"
                :disabled="!editing || noMiddleNameConfirmed"
                id="middle-name"
                class="form-control"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="middle-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2" v-if="!middleNameSet && editing">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <ValidationProvider
              name="middle name confirm"
              :rules="{ required: { allowFalse: false } }"
              v-slot="validationContext"
            >
              <b-form-checkbox
                v-model="noMiddleNameConfirmed"
                type="checkbox"
                id="middle-name-confirm"
                :disabled="!editing"
                :state="getValidationState(validationContext)"
                :aria-invalid="validationContext.errors[0] !== null"
                >Please confirm that you do not have a middle name.</b-form-checkbox
              >
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="last-name" :class="this.showAsteriskForMandatory ? 'mandatory' : ''"> Last Name: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="last name"
              :rules="{ required: true, person_name: 'last name' }"
              v-slot="validationContext"
            >
              <b-form-input
                v-model.trim="personData.lastName"
                id="last-name"
                class="form-control"
                :disabled="!editing"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="last-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="dob" :class="this.showAsteriskForMandatory ? 'mandatory' : ''"> Date of birth: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <date-picker
              v-model="personData.dob"
              :required="true"
              validationFieldName="DOB"
              :disabled="!editing"
              minimumView="day"
              id="person-dob"
            />
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="phone-mobile" :class="this.showAsteriskForMandatory ? 'mandatory' : ''">
                Mobile Telephone:
              </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider name="mobile number" rules="mobile_number|required" v-slot="validationContext">
              <b-form-input
                v-model="personData.phoneMobile"
                id="phone-mobile"
                class="form-control"
                :disabled="!editing"
                :state="getValidationState(validationContext)"
                type="tel"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="phone-mobile-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="email" :class="this.showAsteriskForMandatory ? 'mandatory' : ''"> Email: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="email"
              rules="required|email|valid_email_domain"
              v-slot="validationContext"
              :debounce="500"
            >
              <b-form-input
                v-model="personData.email"
                id="email"
                class="form-control"
                :disabled="!editing || !!personData.cognitoUserId"
                :state="getValidationState(validationContext)"
                type="email"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="email-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
            <small v-if="!!personData.cognitoUserId"
              >Please contact us if you need to change the primary client's email.</small
            >
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label> Correspondence Address (including Post Code): </label>
            </h5>
          </div>
        </div>

        <address-lookup
          class="pb-1"
          v-model="personData.correspondenceAddress"
          :addResidency="false"
          :requiresValidation="editing"
          :displayOnly="!editing"
        ></address-lookup>
      </ValidationObserver>

      <div v-if="editing" class="row pb-2">
        <div class="col-lg-8"></div>

        <div class="col col-lg-2">
          <button class="btn btn-save btn-block" @click="triggerUpdate"></button>
        </div>

        <div class="col col-lg-2">
          <button class="btn btn-cancel btn-block" @click="triggerEdit(false)"></button>
        </div>
      </div>
      <div v-else class="row pb-2">
        <div class="col-lg-10"></div>

        <div class="col col-lg-2">
          <button class="btn bg-primary btn-edit btn-block" @click="triggerEdit(true)"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/deep/ .form-control:disabled {
  color: black;
}
</style>
