<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/sale/mutations';
import TitleDeedAlternatives from '@/components/forms/saleClientQuestionnaire/titleDeedAlternatives/TitleDeedAlternatives';
import TitleDeedAlternativesList from '@/components/forms/saleClientQuestionnaire/titleDeedAlternatives/TitleDeedAlternativesList';

export default {
  name: 'TitleDeedAlternativesSection',
  components: { TitleDeedAlternatives, TitleDeedAlternativesList },
  computed: {
    ...mapState({
      s_sale: (state) => state.sale,
    }),
    titleDeedAlternatives: {
      get() {
        return this.s_sale.titleDeedAlternatives;
      },
      set(value) {
        this[`${mutations.SALE_PREFIX}${mutations.SALE_SET_TITLE_DEED_ALTERNATIVES}`](value);
      },
    },
  },
  data() {
    return {
      showForm: false,
      currentEditedTitleDeedAlternatives: null,
      openFormError: false,
      noPersonAddedError: false,
    };
  },
  methods: {
    ...mapMutations([
      `${mutations.SALE_PREFIX}${mutations.SALE_SET_TITLE_DEED_ALTERNATIVES}`,
      `${mutations.SALE_PREFIX}${mutations.SALE_ADD_TITLE_DEED_ALTERNATIVES}`,
    ]),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    showTitleDeedAlternativesForm() {
      this.resetErrorMessages();
      this.showForm = true;
      this.currentEditedTitleDeedAlternatives = null;
    },
    showList() {
      this.resetErrorMessages();
      this.showForm = false;
    },
    saveNewTitleDeedAlternatives(value) {
      this[`${mutations.SALE_PREFIX}${mutations.SALE_ADD_TITLE_DEED_ALTERNATIVES}`](value);
      this.showList();
    },
    editTitleDeedAlternatives(titleDeedAlternativesIndex) {
      this.resetErrorMessages();
      this.currentEditedTitleDeedAlternatives = this.titleDeedAlternatives[titleDeedAlternativesIndex];

      this.showForm = true;
    },
    saveExistingTitleDeedAlternatives(person) {
      const indexForTitleDeedAlternativesToBeUpdated = this.titleDeedAlternatives.indexOf(
        this.currentEditedTitleDeedAlternatives,
      );

      this.titleDeedAlternatives[indexForTitleDeedAlternativesToBeUpdated] = person;
      this.currentEditedTitleDeedAlternatives = person;

      this.showList();
    },
    resetErrorMessages() {
      this.openFormError = false;
      this.noPersonAddedError = false;
    },
    validPersonAmount() {
      if (this.titleDeedAlternatives.length === 0) {
        this.noPersonAddedError = true;
        return false;
      }
      return true;
    },
    validFormVisibility() {
      if (this.showForm) {
        this.openFormError = true;
        return false;
      }
      return true;
    },
    async validate() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || !this.validPersonAmount() || !this.validFormVisibility()) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      return true;
    },
  },
};
</script>

<template>
  <validation-observer ref="observer">
    <div class="row pb-1">
      <div class="col-md-9">
        <h5>Provide alternative persons registered on the title deed:</h5>
      </div>

      <div class="col-md-3 text-center text-md-right text-lg-right">
        <button
          class="btn btn-add"
          @click="showTitleDeedAlternativesForm"
          v-if="!showForm && titleDeedAlternatives.length < 4"
        ></button>
      </div>
    </div>

    <div class="border-top my-3"></div>

    <div>
      <title-deed-alternatives
        v-if="showForm"
        @cancel="showList()"
        @save-new-title-deed-alternatives="saveNewTitleDeedAlternatives"
        @save-existing-title-deed-alternatives="saveExistingTitleDeedAlternatives"
        v-model="currentEditedTitleDeedAlternatives"
      >
      </title-deed-alternatives>

      <title-deed-alternatives-list
        v-else-if="!showForm && titleDeedAlternatives.length !== 0"
        v-model="titleDeedAlternatives"
        @title-deed-alternatives-edit="editTitleDeedAlternatives"
      >
      </title-deed-alternatives-list>
      <p v-else class="text-muted">No alternative persons registered on the title deed have been added</p>
      <p class="text-danger" v-if="openFormError">Please click on 'Save' or 'Cancel' before proceeding to next page</p>
      <p class="text-danger" v-if="noPersonAddedError">Please add at least one person registered on the title deed</p>
    </div>
  </validation-observer>
</template>
