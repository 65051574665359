import axios from 'axios';
import TitleResult from './title/TitleResult';
import TitleRequest from './title/TitleRequest';

export default class Hmlr {
  static cancelToken;

  static async findTitles(request) {
    if (!(request instanceof TitleRequest)) {
      console.warn('Expected instance of TitleRequest for Hmlr.findTitles');
      return new TitleResult([]);
    }
    if (Hmlr.cancelToken) {
      Hmlr.cancelToken.cancel('Operation canceled due to new request.');
    }
    Hmlr.cancelToken = axios.CancelToken.source();
    const target = `${process.env.VUE_APP_API_ENDPOINT}/land-registry-search?${request.toQueryString()}`;
    const result = await axios
      .get(target, { cancelToken: Hmlr.cancelToken.token, timeout: 10000 })
      .catch(() => console.log('Unable to fetch title.'));
    return new TitleResult(result?.data?.Title ?? result?.data ?? []);
  }
}
