<script>
import HmlrClientNameMismatchSubmit from '@/components/messaging/HmlrClientNameMismatchSubmit';
import HmlrClientNameMismatchTable from '@/components/messaging/HmlrClientNameMismatchTable';

export default {
  name: 'HmlrClientNameMismatch',
  components: {
    HmlrClientNameMismatchSubmit,
    HmlrClientNameMismatchTable,
  },
  data() {
    return {
      pageNo: 1,
    };
  },
  computed: {
    forwardedListeners() {
      // See comments on the template below. This component is a wrapper component which forwards
      // the parent listeners (excluding the 'submit' listener) on to the HmlrClientNameMismatchTable.
      // See https://stackoverflow.com/questions/64218494/how-do-i-use-v-on-listeners-except-for-click#64218600
      const { submit, ...listeners } = this.$listeners;
      return listeners;
    },
  },
  methods: {
    back() {
      this.pageNo = 1;
    },
    continueToSubmit() {
      this.pageNo = 2;
    },
    submit(value) {
      // Forward the 'submit' payload
      this.$emit('submit', value);
    },
  },
};
</script>

<template>
  <div>
    <div v-if="pageNo === 1">
      <!--
        NB: This component is a wrapper component, i.e. it forwards both its props and listeners.
        This saves us from duplicating all the props from the child component and listeners from the parent component here.

        (1) v-on="$listeners" has the effect of forwarding events from the HmlrClientNameMismatchTable directly on
            to its grandparent component (e.g. RemortgageClientQuestionnaire or SaleClientQuestionnaire).

            It does so by applying the listeners from the grandparent component to this component.

            See:
            - https://v2.vuejs.org/v2/api/#vm-listeners
            - https://thewebdev.info/2022/03/10/how-to-emit-event-from-grandchild-to-his-grandparent-component-with-vue-js/
            - https://v2.vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components

        (2) v-bind="$attrs" is used to forward attributes (including props) from the grandparent component directly on
            to the HmlrClientNameMismatchTable.

            See:
             - https://v2.vuejs.org/v2/api/#vm-attrs
             - https://stackoverflow.com/questions/44472276/vuejs-pass-all-props-to-child-component#67297637
      -->
      <hmlr-client-name-mismatch-table
        v-on="forwardedListeners"
        v-bind="$attrs"
        @continue="continueToSubmit"
      ></hmlr-client-name-mismatch-table>
    </div>
    <div v-else-if="pageNo === 2">
      <hmlr-client-name-mismatch-submit @back="back" @submit="submit"></hmlr-client-name-mismatch-submit>
    </div>
  </div>
</template>
