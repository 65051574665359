import axios from 'axios';

export default ({ persons }) => {
  return new Promise((resolve, reject) => {
    Promise.all(
      persons.map((item) => {
        const profileSeller = {
          profile_seller: {
            occupation: item.occupation,
            bankrupt: item.bankrupt,
            name_changed: item.nameChanged,
            availability: item.availability,
            previous_addresses: item.previousAddresses,
            relationship: item.relationship,
            politically_exposed_person: item.politicallyExposedPerson,
          },
        };

        profileSeller.profile_seller.relationship_other = item.relationship === 'Other' ? item.relationshipOther : null;

        profileSeller.profile_seller.bankrupt_date = item.bankrupt ? item.bankruptDate : null;

        profileSeller.profile_seller.name_changed_details = item.nameChanged ? item.nameChangedDetails : '';

        let ni_number = item.niNumber !== null ? item.niNumber.replace(/[ -]/g, '') : '';

        let no_middle_name_confirmed = item.middle_name?.length > 0 ? false : item.noMiddleNameConfirmed;

        let personData = {
          title: item.title,
          first_name: item.firstName,
          middle_name: item.middleName,
          no_middle_name_confirmed: no_middle_name_confirmed,
          last_name: item.lastName,
          previous_name: item.previousName,
          dob: item.dob,
          gender: item.gender,
          nationality: item.nationality,
          correspondence_address: item.correspondenceAddress,
          phone_home: item.phoneHome,
          phone_mobile: item.phoneMobile,
          phone_work: item.phoneWork,
          email: item.email,
          profile_identification: {
            ni_number: ni_number,
          },
          ...profileSeller,
        };

        if (item.hasOwnProperty('personId')) {
          return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/persons/${item.personId}`, personData);
        } else {
          return axios.post(`${process.env.VUE_APP_API_ENDPOINT}/persons`, personData);
        }
      }),
    )
      .then((resultData) => {
        resolve(resultData);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};
