<script>
import DatePicker from '@/components/DatePicker';
import { ToggleButton } from 'vue-js-toggle-button';
import CurrencyInput from '@/components/forms/inputGroups/CurrencyInput';
import LenderOptions from '@/mixins/LenderOptions';

const debtDefaultData = {
  existing_mortgage_lender: '',
  existing_mortgage_number: '',
  amount_owing: 0,
  penalty_fee: false,
  penalty_amount: 0,
  penalty_erc_date: null,
  penalty_postpone: false,
  penalty_waived: false,
  notes: '',
};

export default {
  components: { DatePicker, ToggleButton, CurrencyInput },
  name: 'Debt',
  mixins: [LenderOptions],
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    let penaltyErcDate = null;
    if (this.value !== null && this.value.penalty_erc_date) {
      const ercDate = new Date(this.value.penalty_erc_date.split('/').reverse().join('/'));
      // Disabling eslint due to mismatch between eslint and prettier regarding this next section
      /* eslint-disable */
      penaltyErcDate = `${ercDate.getFullYear()}-${(ercDate.getMonth() + 1).toString().padStart(2, '0')}-${ercDate
        .getDate()
        .toString()
        .padStart(2, '0')}`;
      /* eslint-enable */
    }

    return {
      saving: false,
      debt: {
        ...debtDefaultData,
        ...this.value,
        ...{
          penalty_erc_date: penaltyErcDate,
        },
        ...{
          amount_owing: this.value ? Number(this.value.amount_owing) : 0,
        },
        ...{
          penalty_amount: this.value ? Number(this.value.penalty_amount) : 0,
        },
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }
      if (this.value) {
        this.$emit('save-existing-debt', this.debt);
      } else {
        this.$emit('save-new-debt', this.debt);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    setNewLenderName(value) {
      this.debt.existing_mortgage_lender = this.getLenderNameFromOption(value);
    },
  },
  computed: {
    currentLenderNameOption: {
      get() {
        return this.getLenderNameOptionFromName(this.debt.existing_mortgage_lender);
      },
    },
    showCurrentLenderNameFreeTextInput: {
      get() {
        return this.currentLenderNameOption && this.currentLenderNameOption.id === 0;
      },
    },
    newLenderNameOption: {
      get() {
        return this.getLenderNameOptionFromName(this.debt.existing_mortgage_lender);
      },
    },
  },
};
</script>

<template>
  <ValidationObserver ref="observer" slim>
    <!-- New lender section -->
    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Lender: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <validation-provider name="existing mortgage lender" rules="required" v-slot="validationContext">
          <v-select
            :value="newLenderNameOption"
            @input="setNewLenderName"
            :options="lenders"
            id="existing mortgage lender"
            class="bg-white"
            v-bind:class="{
              'is-valid': !validationContext.errors.length,
              'is-invalid': validationContext.errors.length,
            }"
            :state="getValidationState(validationContext)"
            ><span slot="no-options">{{ noMatchingLenderMessage }}</span></v-select
          >
          <div
            v-if="validationContext.errors"
            v-bind:class="{ 'invalid-feedback d-block': validationContext.errors }"
            data-error-name="existing-mortgage-lender-error"
          >
            {{ validationContext.errors[0] }}
          </div>
        </validation-provider>
      </div>
    </div>

    <div v-if="showCurrentLenderNameFreeTextInput">
      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Lender (Other) </label>
          </h5>
        </div>
        <div class="col-md-9">
          <ValidationProvider name="current lender name" rules="required" v-slot="validationContext">
            <b-form-input
              v-model="debt.existing_mortgage_lender"
              name="currentLenderName"
              debounce="500"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="current-lender-name-other-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <!-- End new lender section -->

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Account Number: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <validation-provider name="existing mortgage number" :rules="{ required: true }" v-slot="validationContext">
          <b-form-input
            v-model="debt.existing_mortgage_number"
            id="existing_mortgage_number"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="existing-mortgage-number-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> Approximate Amount Owing: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <currency-input
          v-model.number="debt.amount_owing"
          validationFieldName="amount owing"
          id="amount-owing"
        ></currency-input>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> Penalty: </label>
        </h5>
      </div>

      <div class="col-md-9 d-flex justify-content-center justify-content-md-start align-items-md-center">
        <validation-provider name="penalty fee" rules="required" v-slot="validationContext">
          <toggle-button
            v-model="debt.penalty_fee"
            id="penalty_fee"
            :labels="{ checked: 'Yes', unchecked: 'No' }"
            :height="35"
            :width="70"
            :font-size="15"
            :color="{ checked: '#20c997', unchecked: '#28b6e6' }"
          />
          <b-form-invalid-feedback data-error-name="penalty-fee-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </div>
    </div>
    <div v-if="debt.penalty_fee">
      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Penalty Amount: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <currency-input
            v-model.number="debt.penalty_amount"
            validationFieldName="penalty amount"
            id="penalty-amount"
          ></currency-input>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Penalty Expiry Date (if known)? </label>
          </h5>
        </div>

        <div class="col-md-9">
          <date-picker
            v-model="debt.penalty_erc_date"
            :required="false"
            minimumView="day"
            id="penalty-erc-date"
          ></date-picker>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="line-height-base"> Postpone Completion until after Penalty Expiry Date? </label>
          </h5>
        </div>

        <div class="col-md-9 d-flex justify-content-center justify-content-md-start align-items-md-center">
          <validation-provider name="penalty postpone" :rules="{ required: true }" v-slot="validationContext">
            <toggle-button
              v-model="debt.penalty_postpone"
              :labels="{ checked: 'Yes', unchecked: 'No' }"
              :height="35"
              :width="70"
              :font-size="15"
              :color="{ checked: '#20c997', unchecked: '#28b6e6' }"
            />
            <b-form-invalid-feedback data-error-name="penalty-postpone-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="line-height-base"> Has the lender indicated they will waive the penalty? </label>
          </h5>
        </div>

        <div class="col-md-9 d-flex justify-content-center justify-content-md-start align-items-md-center">
          <validation-provider name="penalty expiry date" :rules="{ required: true }" v-slot="validationContext">
            <toggle-button
              v-model="debt.penalty_waived"
              :labels="{ checked: 'Yes', unchecked: 'No' }"
              :height="35"
              :width="70"
              :font-size="15"
              :color="{ checked: '#20c997', unchecked: '#28b6e6' }"
            />
            <b-form-invalid-feedback data-error-name="penalty-waived-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Additional Information: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <validation-provider name="debt notes" :rules="{ required: false }" v-slot="validationContext">
            <b-form-textarea
              rows="4"
              v-model="debt.notes"
              id="debt_notes"
              :state="getValidationState(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback data-error-name="debt-notes-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>
    </div>

    <div class="row pb-1 justify-content-end">
      <div class="col-md-3">
        <button @click="cancel" class="btn btn-cancel btn-block mb-1" />
      </div>
      <div class="col-md-3">
        <button id="save_debt" @click="save" class="btn btn-save btn-block" />
      </div>
    </div>
  </ValidationObserver>
</template>
