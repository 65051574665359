import snakeToCamelCase from '../../../functions/snakeToCamelCase';

export const tenureTypes = {
  10: 'freehold',
  20: 'leasehold',
};

export const notSure = 'Not Sure';

export default class TitleResult {
  titles;

  constructor(titles) {
    this.titles = snakeToCamelCase(titles);
  }

  count() {
    return this.titles.length;
  }

  withPurchaseType(purchaseType) {
    const filtered = this.titles.filter(({ tenureInformation: { tenureTypeCode } }) => {
      return tenureTypes[tenureTypeCode] === purchaseType;
    });
    return filtered.length ? new TitleResult(filtered) : this;
  }

  toOptions() {
    const options = this.titles.map(({ address, tenureInformation, titleNumber }) => {
      const addressText = Object.values({ ...address, postcodeZone: address.postcodeZone.postcode }).join(', ');
      const tenureType = tenureInformation.tenureType ?? tenureTypes[tenureInformation.tenureTypeCode];
      return { value: titleNumber, text: `${titleNumber}: ${addressText} (${tenureType})` };
    });
    options.unshift(
      { value: null, text: 'Please select', disabled: true, selected: true },
      { value: notSure, text: notSure },
    );
    return options;
  }
}
