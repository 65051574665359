<script>
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';
import { mapMutations, mapState } from 'vuex';
import * as saleMutations from '@/store/sale/mutations';
import * as buildingMutations from '@/store/building/mutations';
import patchSale from '@/api/sales/patchSale';
import patchBuilding from '@/api/buildings/patchBuilding';
import postSale from '@/api/sales/postSale';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import DatePicker from '@/components/DatePicker';
import CurrencyInput from '@/components/forms/inputGroups/CurrencyInput';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import SellingCompanyDetails from '@/components/forms/saleClientQuestionnaire/SellingCompanyDetails';
import TitleDeedAlternativesSection from '@/components/forms/saleClientQuestionnaire/TitleDeedAlternativesSection';
import Loader from '@/components/Loader';
import Modal from '@/components/Modal';
import CouncilTaxBandInfo from '@/components/messaging/CouncilTaxBandInfo';
import Hmlr from '../../../api/hmlr/Hmlr';
import TitleRequest from '../../../api/hmlr/title/TitleRequest';
import TitleResult, { notSure } from '../../../api/hmlr/title/TitleResult';

const propertyTypesValues = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'maisonette', text: 'Maisonette' },
  { value: 'house', text: 'House' },
  { value: 'flat', text: 'Flat' },
  { value: 'other', text: 'Other' },
];

const surplusPaymentMethods = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'purchase', text: 'Proceeds for Purchase' },
  { value: 'BACS', text: 'Transferred in three days (BACs)' },
  { value: 'transfer', text: 'Transferred same day (CHAPS) *apply fee' },
];

const purchaseTypeValues = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'freehold', text: 'Freehold' },
  { value: 'leasehold', text: 'Leasehold' },
  { value: 'leasehold - long lease house', text: 'Leasehold - Long Lease House' },
];

const councilTaxBandValues = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'A', text: 'A' },
  { value: 'B', text: 'B' },
  { value: 'C', text: 'C' },
  { value: 'D', text: 'D' },
  { value: 'E', text: 'E' },
  { value: 'F', text: 'F' },
  { value: 'G', text: 'G' },
  { value: 'H', text: 'H' },
  { value: 'Not Known', text: 'Not Known' },
];

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrNotKnown = {
  YNNK_YES: 'Yes',
  YNNK_NO: 'No',
  YNNK_NOT_KNOWN: 'Not Known',
};

export default {
  name: 'AboutYourSale',
  data() {
    return {
      propertyTypesValues,
      purchaseTypeValues,
      councilTaxBandValues,
      showCouncilTaxBandInfo: false,
      surplusPaymentMethods,
      yesOrNo,
      yesOrNoOrNotKnown,
      titleResults: new TitleResult([]),
      titleResultsLoading: false,
    };
  },
  computed: {
    ...mapState({
      s_buildingId: (state) => state.buildingId,
      s_sortReferId: (state) => state.sortReferId,
      s_saleId: (state) => state.sale.saleId,
      s_propertyType: (state) => state.sale.propertyType,
      s_propertyTypeOther: (state) => state.sale.propertyTypeOther,
      s_purchaseType: (state) => state.sale.purchaseType,
      s_councilTaxBand: (state) => state.sale.councilTaxBand,
      s_leaseholdToFreehold: (state) => state.sale.leaseholdToFreehold,
      s_buyerName: (state) => state.sale.buyerName,
      s_salePrice: (state) => state.sale.salePrice,
      s_postCompletionAddress: (state) => state.sale.postCompletionAddress,
      s_fixturePrice: (state) => state.sale.fixturePrice,
      s_specialPurchase: (state) => state.sale.specialPurchase,
      s_specialPurchaseDetails: (state) => state.sale.specialPurchaseDetails,
      s_postCompletionPhone: (state) => state.sale.postCompletionPhone,
      s_titleNumbers: (state) => state.sale.titleNumbers,
      s_desiredCompletionDate: (state) => state.sale.desiredCompletionDate,
      s_exchangeDeadline: (state) => state.sale.exchangeDeadline,
      s_completionDeadline: (state) => state.sale.completionDeadline,
      s_surplusPaymentMethod: (state) => state.sale.surplusPaymentMethod,
      s_chiefOrGroundRent: (state) => state.sale.chiefOrGroundRent,
      s_chiefOrGroundRentAddress: (state) => state.sale.chiefOrGroundRentAddress,
      s_chiefOrGroundRentPhone: (state) => state.sale.chiefOrGroundRentPhone,
      s_titleDeedDeclared: (state) => state.sale.titleDeedDeclared,
      s_titleDeedAlternatives: (state) => state.sale.titleDeedAlternatives,
      s_saleAddressUpdated: (state) => state.sale.saleAddressUpdated,
      s_passStatementsToBroker: (state) => state.sale.passStatementsToBroker,
      s_soldByCompany: (state) => state.sale.soldByCompany,
      s_soldByCompanyDetails: (state) => state.sale.soldByCompanyDetails,
      s_saleAddress: (state) => state.building.address,
      s_sale: (state) => state.sale,
    }),
    saleAddressUpdated: {
      get() {
        return this.s_saleAddressUpdated;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SALE_ADDRESS_UPDATED}`](value);
      },
    },
    propertyType: {
      get() {
        return this.s_propertyType;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PROPERTY_TYPE}`](value);
      },
    },
    propertyTypeOther: {
      get() {
        return this.s_propertyTypeOther;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PROPERTY_TYPE_OTHER}`](value);
      },
    },
    saleAddress: {
      get() {
        return this.s_saleAddress;
      },
      set(value) {
        this[`${buildingMutations.BUILDING_PREFIX}${buildingMutations.BUILDING_SET_ADDRESS}`](value);
      },
    },
    purchaseType: {
      get() {
        return this.s_purchaseType;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PURCHASE_TYPE}`](value);
      },
    },
    councilTaxBand: {
      get() {
        return this.s_councilTaxBand;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_COUNCIL_TAX_BAND}`](value);
      },
    },
    leaseholdToFreehold: {
      get() {
        return {
          answer: this.s_leaseholdToFreehold,
        };
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_LEASEHOLD_TO_FREEHOLD}`](value.answer);
      },
    },
    buyerName: {
      get() {
        return this.s_buyerName;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BUYER_NAME}`](value);
      },
    },
    specialPurchase: {
      get() {
        return {
          answer: this.s_specialPurchase,
          description: this.s_specialPurchaseDetails,
        };
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SPECIAL_PURCHASE}`](value.answer);
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SPECIAL_PURCHASE_DETAILS}`](value.description);
      },
    },
    salePrice: {
      get() {
        return this.s_salePrice;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PRICE}`](Number(value));
      },
    },
    fixturePrice: {
      get() {
        return this.s_fixturePrice;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_FIXTURE_PRICE}`](Number(value));
      },
    },
    titleNumbers: {
      get() {
        return this.s_titleNumbers;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_TITLE_NUMBERS}`](value);
      },
    },
    desiredCompletionDate: {
      get() {
        return this.s_desiredCompletionDate;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_DESIRED_COMPLETION_DATE}`](value);
      },
    },
    exchangeDeadline: {
      get() {
        return this.s_exchangeDeadline;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_EXCHANGE_DEADLINE}`](value);
      },
    },
    completionDeadline: {
      get() {
        return this.s_completionDeadline;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_COMPLETION_DEADLINE}`](value);
      },
    },
    surplusPaymentMethod: {
      get() {
        return this.s_surplusPaymentMethod;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SURPLUS_PAYMENT_METHOD}`](value);
      },
    },
    postCompletionPhone: {
      get() {
        return this.s_postCompletionPhone;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_POST_COMPLETION_PHONE}`](value);
      },
    },
    postCompletionAddress: {
      get() {
        return this.s_postCompletionAddress;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_POST_COMPLETION_ADDRESS}`](value);
      },
    },
    chiefOrGroundRent: {
      get() {
        return {
          answer: this.s_chiefOrGroundRent,
        };
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_CHIEF_OR_GROUND_RENT}`](value.answer);
      },
    },
    chiefOrGroundRentAddress: {
      get() {
        return this.s_chiefOrGroundRentAddress;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_CHIEF_OR_GROUND_RENT_ADDRESS}`](value);
      },
    },
    chiefOrGroundRentPhone: {
      get() {
        return this.s_chiefOrGroundRentPhone;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_CHIEF_OR_GROUND_RENT_PHONE}`](value);
      },
    },
    titleDeedDeclared: {
      get() {
        return {
          answer: this.s_titleDeedDeclared,
        };
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_TITLE_DEED_DECLARED}`](value.answer);
      },
    },
    passStatementsToBroker: {
      get() {
        return {
          answer: this.s_passStatementsToBroker,
        };
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PASS_STATEMENTS_TO_BROKER}`](value.answer);
      },
    },
    soldByCompany: {
      get() {
        return {
          answer: this.s_soldByCompany,
        };
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SOLD_BY_COMPANY}`](value.answer);
      },
    },
    soldByCompanyDetails: {
      get() {
        return this.s_soldByCompanyDetails;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SOLD_BY_COMPANY_DETAILS}`](value);
      },
    },
  },
  components: {
    Loader,
    AddressLookup,
    DatePicker,
    SimpleQuestion,
    OpenQuestion,
    CurrencyInput,
    Modal,
    TitleDeedAlternativesSection,
    CouncilTaxBandInfo,
    SellingCompanyDetails,
  },
  methods: {
    ...mapMutations([
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PROPERTY_TYPE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PROPERTY_TYPE_OTHER}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PURCHASE_TYPE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_COUNCIL_TAX_BAND}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_LEASEHOLD_TO_FREEHOLD}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_BUYER_NAME}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PRICE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_FIXTURE_PRICE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_TITLE_NUMBERS}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_DESIRED_COMPLETION_DATE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_EXCHANGE_DEADLINE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_COMPLETION_DEADLINE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SURPLUS_PAYMENT_METHOD}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_POST_COMPLETION_PHONE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_POST_COMPLETION_ADDRESS}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_RELATED_CASES}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_UPDATED_RELATED_CASES_ITEM}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_ADD_NEW_RELATED_CASE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SPECIAL_PURCHASE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SPECIAL_PURCHASE_DETAILS}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_CHIEF_OR_GROUND_RENT}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_CHIEF_OR_GROUND_RENT_ADDRESS}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_CHIEF_OR_GROUND_RENT_PHONE}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_TITLE_DEED_DECLARED}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SALE_ADDRESS_UPDATED}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_PASS_STATEMENTS_TO_BROKER}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SOLD_BY_COMPANY}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_SOLD_BY_COMPANY_DETAILS}`,
      `${buildingMutations.BUILDING_PREFIX}${buildingMutations.BUILDING_SET_ADDRESS}`,
    ]),
    async gotFullAddressInfo(address) {
      await this.fetchTitleNumbers(TitleRequest.fromSearchResult(this.s_saleId, address));
    },
    async fetchTitleNumbers(titleRequest) {
      this.titleResultsLoading = true;
      const titleResult = await Hmlr.findTitles(titleRequest);
      this.titleResults = titleResult.withPurchaseType(this.purchaseType);
      if (this.titleResults.count() === 1) {
        this.titleNumbers = this.titleResults.titles[0].TitleNumber;
      }
      this.titleResultsLoading = false;
    },
    async isValid() {
      const isMainFormValid = await this.$refs.observer.validate();
      if (!this.s_titleDeedDeclared && !this.s_soldByCompany) {
        const isTitleDeedFormValid = await this.$refs.titleDeedAlternativesSection.validate();
        return isMainFormValid && isTitleDeedFormValid;
      }

      return isMainFormValid;
    },
    async saveToApi() {
      const isValid = await this.isValid();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }

      if (this.s_soldByCompany) {
        console.log('soldByCompany trigger', this.s_soldByCompany);
        await this.$refs.soldByCompanyDetails.triggerUpdate();
      }

      this.saving = true;
      const preparedData = {
        buildingId: this.s_buildingId,
        sortReferId: this.s_sortReferId,
        propertyType: this.s_propertyType,
        propertyTypeOther: this.s_propertyTypeOther,
        salePrice: this.s_salePrice,
        purchaseType: this.s_purchaseType,
        councilTaxBand: this.s_councilTaxBand,
        buyerName: this.s_buyerName,
        fixturePrice: this.s_fixturePrice,
        saleAddress: this.s_saleAddress,
        leaseholdToFreehold: this.s_leaseholdToFreehold,
        titleNumbers: (this.s_titleNumbers ?? '').replace(notSure, ''),
        desiredCompletionDate: this.s_desiredCompletionDate,
        exchangeDeadline: this.s_exchangeDeadline,
        completionDeadline: this.s_completionDeadline,
        surplusPaymentMethod: this.s_surplusPaymentMethod,
        postCompletionAddress: this.s_postCompletionAddress,
        postCompletionPhone: this.s_postCompletionPhone,
        specialPurchase: this.s_specialPurchase,
        specialPurchaseDetails: this.s_specialPurchaseDetails,
        chiefOrGroundRent: this.s_chiefOrGroundRent,
        chiefOrGroundRentAddress: this.s_chiefOrGroundRentAddress,
        chiefOrGroundRentPhone: this.s_chiefOrGroundRentPhone,
        titleDeedDeclared: this.s_titleDeedDeclared,
        titleDeedAlternatives: this.s_titleDeedAlternatives,
        passStatementsToBroker: this.s_passStatementsToBroker,
        soldByCompany: this.s_soldByCompany,
        soldByCompanyDetails: this.s_soldByCompanyDetails,
      };

      if (this.s_saleId) {
        await patchSale({ ...preparedData, ...{ id: this.s_saleId } });
      } else {
        await postSale(preparedData);
      }
      await patchBuilding({
        id: this.s_buildingId,
        address: this.s_saleAddress,
      });

      // force rerender review page
      this.saleAddressUpdated = true;

      this.saving = false;

      return true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    toggleCouncilTaxBandInfo(value) {
      this.showCouncilTaxBandInfo = value;
    },
  },
};
</script>

<template>
  <validation-observer ref="observer">
    <div>
      <h2>About Your Sale</h2>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Property Type: </label>
          </h5>
        </div>
        <div class="col-md-9">
          <ValidationProvider v-slot="validationContext" name="property type" rules="required">
            <b-form-select
              id="property-type"
              v-model="propertyType"
              :options="propertyTypesValues"
              :state="getValidationState(validationContext)"
              class="form-control bg-white"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="property-type-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2" v-if="propertyType === 'other'">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Other Property Type: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="other property type" rules="required|min:3" v-slot="validationContext">
            <b-form-input
              :state="getValidationState(validationContext)"
              class="form-control"
              id="property-type-other"
              v-model="propertyTypeOther"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="property-type-other-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Property Tenure: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider v-slot="validationContext" name="property tenure" rules="required">
            <b-form-select
              id="purchase-type"
              v-model="purchaseType"
              :disabled="true"
              :options="purchaseTypeValues"
              :state="getValidationState(validationContext)"
              class="form-control bg-white"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="purchase-type-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="purchaseType === 'freehold'" class="row pb-2">
        <div class="col-md-12">
          <simple-question
            v-model="leaseholdToFreehold"
            :dropdownOptions="yesOrNo"
            labelName="leaseholdToFreeholdLabelName"
            title="Did you purchase the property Leasehold and then later purchase the Freehold:"
            validationFieldName="leasehold to freehold"
          ></simple-question>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Council Tax Band: </label>
          </h5>
        </div>

        <div class="col-md-6">
          <div class="d-flex align-items-center justify-content-md-start pt-1 pb-1">
            <span class="text-info" @click="toggleCouncilTaxBandInfo(true)">
              <i class="icon-info22 mr-2 icon"></i>
              <span class="text-nowrap">More info</span>
            </span>
          </div>
        </div>

        <div class="col-md-3">
          <ValidationProvider v-slot="validationContext" name="council tax band" rules="required">
            <b-form-select
              id="council-tax-band"
              v-model="councilTaxBand"
              :options="councilTaxBandValues"
              :state="getValidationState(validationContext)"
              class="form-control bg-white"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="council-tax-band-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Sale Address: </label>
          </h5>
        </div>
      </div>

      <address-lookup
        :key="saleAddressUpdated"
        v-model="saleAddress"
        :addResidency="false"
        @gotFullAddressInfo="gotFullAddressInfo"
      ></address-lookup>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Buyer Name: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider v-slot="validationContext" name="buyer name" rules="alpha_spaces">
            <b-form-input
              id="buyer-name"
              v-model="buyerName"
              :state="getValidationState(validationContext)"
              class="form-control"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="buyer-name-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Sale Price: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <currency-input
            id="sale-price"
            v-model="salePrice"
            :required="true"
            validationFieldName="sale price"
          ></currency-input>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Price for Fixtures & Fittings (if any): </label>
          </h5>
        </div>

        <div class="col-md-9">
          <currency-input
            id="fixture-price"
            v-model="fixturePrice"
            validationFieldName="fixture-price"
          ></currency-input>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Title Number(s) if known: </label>
          </h5>
        </div>

        <div v-if="titleResults.count() === 0 && titleResultsLoading === false" class="col-md-9">
          <ValidationProvider v-slot="validationContext" name="title numbers">
            <b-form-input
              id="title-numbers"
              v-model="titleNumbers"
              :state="getValidationState(validationContext)"
              class="form-control"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="title-numbers-error">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
        <div v-else-if="titleResultsLoading === false" class="col-md-9">
          <ValidationProvider v-slot="validationContext" :rules="{ required: false }" name="title numbers">
            <b-form-select
              id="title-numbers-select"
              v-model="titleNumbers"
              :options="titleResults.toOptions()"
              :state="getValidationState(validationContext)"
              aria-describedby="title-numbers-error"
              class="form-control bg-white"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="title-numbers-error">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
        <div v-else class="col-md-9">
          <loader></loader>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <simple-question
            v-model="chiefOrGroundRent"
            :dropdownOptions="yesOrNoOrNotKnown"
            labelName="chiefOrGroundRentLabelName"
            title="Is the Property you are selling subject to any Chief or Ground Rent?"
            validationFieldName="subject to any Chief or Ground Rent"
          ></simple-question>
        </div>
      </div>

      <div v-if="chiefOrGroundRent.answer === 'YNNK_YES'">
        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="line-height-base mandatory"
                >If yes, please give the name and address of the person to whom this is paid and provide the receipt for
                the last payment made:</label
              >
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider v-slot="validationContext" name="recipient contact details" rules="required|min:3">
              <b-form-textarea
                id="chief-or-ground-rent-address"
                v-model="chiefOrGroundRentAddress"
                :state="getValidationState(validationContext)"
                rows="4"
              ></b-form-textarea>
              <b-form-invalid-feedback
                v-if="validationContext.errors[0]"
                data-error-name="chief-or-ground-rent-address-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label class="line-height-base mandatory">Recipient Telephone Number: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              v-slot="validationContext"
              name="recipient telephone number"
              rules="required|phone_number"
            >
              <b-form-input
                id="chief-or-ground-rent-phone"
                v-model="chiefOrGroundRentPhone"
                :state="getValidationState(validationContext)"
                class="form-control"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="chief-or-ground-rent-phone-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Desired Completion Date: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <date-picker id="desired-completion-date" v-model="desiredCompletionDate" minimumView="day" />
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="line-height-base"> Imposed Exchange Deadline (if any): </label>
          </h5>
        </div>

        <div class="col-md-9">
          <date-picker id="exchange-deadline" v-model="exchangeDeadline" minimumView="day" />
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="line-height-base"> Imposed Completion Deadline (if any): </label>
          </h5>
        </div>

        <div class="col-md-9">
          <date-picker id="completion-deadline" v-model="completionDeadline" minimumView="day" />
        </div>
      </div>

      <div class="row pb-1">
        <div class="col-md-3">
          <h5>
            <label> Post Completion Address: </label>
          </h5>
        </div>
      </div>

      <address-lookup
        v-model="postCompletionAddress"
        :addResidency="false"
        :requiresValidation="false"
      ></address-lookup>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="line-height-base"> Post Completion Telephone Number: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider v-slot="validationContext" name="post completion phone" rules="phone_number">
            <b-form-input
              id="post-completion-phone"
              v-model="postCompletionPhone"
              :state="getValidationState(validationContext)"
              class="form-control"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="post-completion-phone-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="line-height-base mandatory">
              How do you wish surplus proceeds from this Sale to be paid?
            </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider v-slot="validationContext" name="surplus payment method" rules="required">
            <b-form-select
              id="surplus-payment-method"
              v-model="surplusPaymentMethod"
              :options="surplusPaymentMethods"
              :state="getValidationState(validationContext)"
              class="form-control bg-white"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="surplus-payment-method-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <open-question
            v-model="specialPurchase"
            dropdownName="specialPurchaseDropdownName"
            labelName="specialPurchaseLabelName"
            textAreaName="specialPurchaseTextAreaName"
            title="Have you during the last 5 years either purchased your property at a discount from a Local Authority
           or Housing Association or had the benefit of an Improvement or Repairs Grant?"
            validationFieldName="special purchase"
          ></open-question>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <simple-question
            v-model="titleDeedDeclared"
            :dropdownOptions="yesOrNo"
            labelName="titleDeedDeclaredLabelName"
            title="Is the title deed for this property registered in the names of the persons declared in this questionnaire?"
            validationFieldName="title deed for this property"
          ></simple-question>
        </div>
      </div>

      <div v-if="titleDeedDeclared.answer === false">
        <div class="row pb-2">
          <div class="col-md-12">
            <simple-question
              v-model="soldByCompany"
              :dropdownOptions="yesOrNo"
              labelName="soldByCompanyLabelName"
              title="Is the seller a company?"
              validationFieldName="is the seller a company"
            ></simple-question>
          </div>
        </div>

        <div v-if="soldByCompany.answer">
          <selling-company-details v-model="soldByCompanyDetails" ref="soldByCompanyDetails" />
        </div>
        <div v-if="soldByCompany.answer === false">
          <title-deed-alternatives-section ref="titleDeedAlternativesSection" />
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <div class="section-title">
            <h3>Permission to discuss your matter</h3>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="passStatementsToBrokerLabelName"
            title="Are you happy for us to share your matter details and documents with your broker/estate agent (if requested by
            them) during the course of your matter?"
            v-model="passStatementsToBroker"
            validationFieldName="pass statements to broker"
          />
        </div>
      </div>

      <modal
        v-if="showCouncilTaxBandInfo"
        :show-buttons="true"
        closeButtonText="Close"
        :fullModalWidth="false"
        @proceed="toggleCouncilTaxBandInfo"
      >
        <council-tax-band-info />
      </modal>
    </div>
  </validation-observer>
</template>

<style lang="scss" scoped></style>
