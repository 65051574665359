import axios from 'axios';

export default ({
  clientIds,
  authorityPurchase,
  authorityPurchaseDetails,
  propertyType,
  purchaseType,
  councilTaxBand,
  buyerName,
  salePrice,
  fixturePrice,
  agentFee,
  agentFeeType,
  agentFeePaymentPermission,
  relatedCase,
  relatedCases,
  specialPurchase,
  specialPurchaseDetails,
  occupierIds,
  mortgagesOrCharges,
  leaseholdToFreehold,
  titleNumbers,
  desiredCompletionDate,
  exchangeDeadline,
  completionDeadline,
  surplusPaymentMethod,
  postCompletionPhone,
  postCompletionAddress,
  sagepayTransactionId,
  chiefOrGroundRent,
  chiefOrGroundRentAddress,
  chiefOrGroundRentPhone,
  titleDeedDeclared,
  titleDeedAlternative,
  passStatementsToBroker,
  buildingId,
}) =>
  axios.post(`${process.env.VUE_APP_API_ENDPOINT}/sales`, {
    client_ids: clientIds,
    authority_purchase: authorityPurchase,
    authority_purchase_details: authorityPurchaseDetails,
    property_type: propertyType,
    purchase_type: purchaseType,
    council_tax_band: councilTaxBand,
    buyer_name: buyerName,
    sale_price: salePrice,
    fixture_price: fixturePrice,
    agent_fee: agentFee,
    agent_fee_type: agentFeeType,
    agent_fee_payment_permission: agentFeePaymentPermission,
    related_case: relatedCase,
    related_cases: relatedCases,
    special_purchase: specialPurchase,
    special_purchase_details: specialPurchaseDetails,
    occupier_ids: occupierIds,
    mortgages_or_charges: mortgagesOrCharges,
    leasehold_to_freehold: leaseholdToFreehold,
    title_numbers: titleNumbers,
    desired_completion_date: desiredCompletionDate,
    exchange_deadline: exchangeDeadline,
    completion_deadline: completionDeadline,
    surplus_payment_method: surplusPaymentMethod,
    post_completion_phone: postCompletionPhone,
    post_completion_address: postCompletionAddress,
    sagepay_transaction_id: sagepayTransactionId,
    chief_or_ground_rent: chiefOrGroundRent,
    chief_or_ground_rent_address: chiefOrGroundRentAddress,
    chief_or_ground_rent_phone: chiefOrGroundRentPhone,
    title_deed_declared: titleDeedDeclared,
    title_deed_alternative: titleDeedAlternative,
    pass_statements_to_broker: passStatementsToBroker,
    building_id: buildingId,
  });
