<script>
import Modal from '@/components/Modal';
import previewDocument from '@/functions/document/previewDocument';

export default {
  name: 'HmlrClientNameMismatchTable',
  components: { Modal },
  props: {
    nameMatches: Array,
    titleDocumentId: String,
    amendPersonsOnTitleText: {
      type: String,
      default: 'Amend clients',
    },
    showAmendRemovedPersonsButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDownloading: false,
      // Values to be emitted by modal buttons
      continueValue: 'continue',
      amendPersonsOnTitleValue: 'amendPersonsOnTitle',
      amendRemovedPersonsValue: 'amendRemovedPersons',
    };
  },
  methods: {
    handleProceed(value) {
      const validValues = [this.continueValue, this.amendPersonsOnTitleValue, this.amendRemovedPersonsValue];
      if (!validValues.includes(value)) {
        console.error('Unrecognized value');
      }
      this.$emit(value);
    },
    async downloadTitleDocument() {
      if (!this.isDownloading) {
        this.isDownloading = true;
        try {
          await previewDocument({
            id: this.titleDocumentId,
            document_name: 'title_document',
          });
        } catch {
          // do nothing
        }
        this.isDownloading = false;
      }
    },
  },
  computed: {
    amendToeRemovedPersonsText: {
      get() {
        // The modal component does not display empty buttons, so setting it's
        // text to an empty string causes it to be hidden.
        return this.showAmendRemovedPersonsButton ? 'Amend transfer of equity' : '';
      },
    },
  },
};
</script>

<template>
  <modal
    :closeButtonText="amendPersonsOnTitleText"
    :closeButtonEmitValue="amendPersonsOnTitleValue"
    :closeButton2Text="amendToeRemovedPersonsText"
    :closeButton2EmitValue="amendRemovedPersonsValue"
    proceedButtonText="Continue &amp; provide reason"
    :proceedButtonEmitValue="continueValue"
    :showHeader="false"
    :showButtons="true"
    @proceed="handleProceed"
  >
    <h2 class="text-center mb-3">HMLR Title - Name Check</h2>
    <p class="font-size-lg">
      To prevent delays to your case it is vital that we have your full legal name. We have checked the name(s) entered
      on your Client Questionnaire against those registered on the Title Deed of your property
      <template v-if="titleDocumentId">
        (click <a @click.prevent="downloadTitleDocument()" href="#">here</a> to download)</template
      >.
    </p>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr class="d-flex">
            <th class="col-5">Named person on questionnaire</th>
            <th class="col-4">Proprietor on title</th>
            <th class="col-3">Exact match?</th>
          </tr>
        </thead>
        <tr v-for="(item, index) in nameMatches" :key="index" class="d-flex">
          <td class="col-5 font-size-lg">{{ item.client_name }}</td>
          <td class="col-4 font-size-lg">{{ item.proprietor_name }}</td>
          <td
            class="col-3 font-size-lg"
            v-bind:class="{
              'text-success': item.is_exact_match,
              'text-danger': !item.is_exact_match,
            }"
          >
            {{ item.is_exact_match | yesno }}
          </td>
        </tr>
      </table>
    </div>
    <div class="font-size-lg">
      <h5 class="text-center">There is a mismatch - please either:<br /><br /></h5>
      <ul>
        <li>Click <strong>continue</strong> to provide a reason (e.g. marriage, divorce, other change of name).</li>
        <li><strong>Amend</strong> the questionnaire to include any missing names/correct errors.</li>
      </ul>
    </div>
  </modal>
</template>
