<script>
const rawCompany = {
  company_name: null,
  company_number: null,
  director_or_authorised_person: null,
  country_of_incorporation: null,
};

export default {
  name: 'SellingCompanyDetails',
  data() {
    return {
      company: {
        ...rawCompany,
        ...this.value,
      },
    };
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    triggerUpdate() {
      this.$emit('input', this.company);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div>
    <!-- Company Name -->
    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="line-height-base"> Company Name: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider v-slot="validationContext" name="company name" rules="required">
          <b-form-input
            id="company-name"
            v-model="company.company_name"
            :state="getValidationState(validationContext)"
            class="form-control"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="company-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>
    <!-- /End Company Name -->

    <!-- Company Number -->
    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="line-height-base"> Company Number: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider v-slot="validationContext" name="company number" rules="required">
          <b-form-input
            id="company-number"
            v-model="company.company_number"
            :state="getValidationState(validationContext)"
            class="form-control"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="company-number-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>
    <!-- /End Company Number -->

    <!-- Director/authorised person -->
    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="line-height-base"> Director/Authorised Person: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider v-slot="validationContext" name="director or authorised person" rules="required">
          <b-form-input
            id="director-authorised-person"
            v-model="company.director_or_authorised_person"
            :state="getValidationState(validationContext)"
            class="form-control"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="director-authorised-person-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>
    <!-- /End Director/authorised person -->

    <!-- Country of incorporation -->
    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="line-height-base"> Country Of Incorporation: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider v-slot="validationContext" name="country of incorporation" rules="required">
          <b-form-input
            id="country-of-incorporation"
            v-model="company.country_of_incorporation"
            :state="getValidationState(validationContext)"
            class="form-control"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="country-of-incorporation-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>
    <!-- /End Country of incorporation -->
  </div>
</template>
