<script>
import { mapActions, mapState } from 'vuex';
import FormWrapper from '@/components/FormWrapper';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import MortgagesOrCharges from '@/components/forms/saleClientQuestionnaire/MortgagesOrCharges';
import AboutYou from '@/components/forms/saleClientQuestionnaire/AboutYou';
import OccupiersSaleQuestionnaire from '@/components/forms/saleClientQuestionnaire/OccupiersQuestionnaire';
import AboutYourSale from '@/components/forms/saleClientQuestionnaire/AboutYourSale';
import YourEstateAgent from '@/components/forms/saleClientQuestionnaire/YourEstateAgent';
import RelatedPurchaseOrRemortgage from '@/components/forms/saleClientQuestionnaire/RelatedPurchaseOrRemortgage';
import HmlrClientNameMismatch from '@/components/messaging/HmlrClientNameMismatch';
import HmlrPending from '@/components/messaging/HmlrPending';
import Modal from '@/components/Modal';
import SummaryPage from '@/components/forms/SummaryPage';
import PdfPreview from '@/components/forms/SalePDFpreview';
import SaveProgressInfo from '@/components/messaging/SaveProgressInfo';
import SaleReviewPage from '@/components/forms/saleClientQuestionnaire/SaleReviewPage';
import Other from '@/components/forms/saleClientQuestionnaire/OtherQuestionnaire';
import * as saleActions from '@/store/sale/actions';
import {
  AWAITING_RESPONSE_DOCUMENT_ANALYSIS,
  AWAITING_RESPONSE_HMLR,
  NAME_MISMATCH,
  READY_TO_SUBMIT,
  SUBMISSION_NOT_INITIATED,
} from '@/values/cqSubmissionStatuses';
import FormCompletion from '@/mixins/FormCompletion';

const aboutYouTabIndex = 0;
const aboutYourSaleIndex = 1;
const defaultReviewPageTabIndex = 7; // note that an extra tab is added for Sort Legal; see usages

// Config to set number of times to poll the form submission status
// before timing out. We allow a reasonable amount of time because
// we need to wait for the fetching of the HMLR title and subsequent
// document analysis to complete.
const statusPollInterval = 3000;
const maxPollPeriod = 90000;
const maxPollAttempts = maxPollPeriod / statusPollInterval;

export default {
  name: 'SaleClientQuestionnaire',
  components: {
    RelatedPurchaseOrRemortgage,
    MortgagesOrCharges,
    FormWrapper,
    FormWizard,
    TabContent,
    WizardButton,
    AboutYou,
    OccupiersSaleQuestionnaire,
    AboutYourSale,
    YourEstateAgent,
    Modal,
    SummaryPage,
    PdfPreview,
    SaveProgressInfo,
    SaleReviewPage,
    Other,
    HmlrClientNameMismatch,
    HmlrPending,
  },
  mixins: [FormCompletion],
  data() {
    return {
      disabled: false,
      clientQuestionnaireForm: 0,
      showSubmitModal: false,
      wizardLoading: false,
      hideWizardNextButton: false,
      avoidForceRerender: false,
      amendingPersonsOnTitle: false,
      // Are we currently submitting the form?
      isSubmitting: false,
      // Is the user submitting despite a name mismatch?
      isForceSubmit: false,
      pollAttemptNo: 0,
    };
  },
  props: ['entityType', 'entityId'],
  methods: {
    ...mapActions('sale', ['addTrackingEvent', 'setEventToHandle']),
    setLoading(value) {
      this.wizardLoading = value;
    },
    forceRerender() {
      this.clientQuestionnaireForm++;
    },
    saveDebts: function () {
      return this.$refs.debts.saveToApi();
    },
    validateOther: function () {
      return this.$refs.other.saveToApi();
    },
    saveRelatedCases: function () {
      return this.$refs.relatedCases.saveToApi();
    },
    saveEstateAgent: function () {
      return this.$refs.yourEstateAgent.saveToApi();
    },
    saveSales: function () {
      return this.$refs.aboutYourSale.saveToApi();
    },
    validateOccupiers: function () {
      return this.$refs.occupiersSaleQuestionnaire.validatePage();
    },
    validatePerson: function () {
      return this.$refs.aboutYou.validatePersonsPayload();
    },
    validateReviewConfirmation: function () {
      return this.$refs.reviewPage.validateConfirmation();
    },
    showPdfPreview: function (downloadFile = false) {
      return this.$refs.pdfPreview.getPdf(downloadFile, 'sale-questionnaire-form-');
    },
    saveAndRedirect: async function () {
      this.navigate('ViewEntity', { entityType: this.entityType, entityId: this.entityId });
    },
    saveAndShowModalBeforeSubmit: async function () {
      this.showSubmitModal = true;
    },
    async trySubmitForm(value) {
      this.showSubmitModal = false;
      if (value) {
        // Reset the submission status so we don't immediately see name mismatches
        // from previous submission attempts.
        await this.$store.dispatch(saleActions.SALE_RESET_SUBMISSION_STATUS);
        this.disabled = true;
        this.isSubmitting = true;
        this.amendingPersonsOnTitle = false;

        try {
          // Check if the user has specified a reason for a mismatch between
          // the names of the client(s) and proprietor(s) and, if so, include
          // it in the form submission payload.
          this.isForceSubmit = typeof value === 'object' && value.hasOwnProperty('reason');
          const reason = this.isForceSubmit ? value.reason : null;
          const additionalInfo = this.isForceSubmit ? value.additionalInfo : null;
          const actionPayload = { saleId: this.entityId, reason, additionalInfo };

          await this.$store.dispatch(saleActions.SALE_TRY_SUBMIT, actionPayload);
          this.isForceSubmit = false;
          this.processFormSubmissionStatus();

          // NB: See also the 'displayPendingDialog' watch function, which
          // handles initiation of polling of the submission status when
          // the user navigates back to this page.
        } catch (err) {
          console.debug(err);
        }
      }
    },
    processFormSubmissionStatus() {
      // If we're awaiting a response, check the status again in a few seconds.
      // We also do this if the status is 'submission_not_initiated' but
      // isSubmitting is true, as the background job which orders
      // the HMLR documents can take a few seconds to start.
      if (this.pollSubmissionStatus) {
        if (!this.pollTimedOut) {
          this.pollAttemptNo++;

          setTimeout(async () => {
            await this.$store.dispatch(saleActions.SALE_FETCH_SUBMISSION_STATUS, this.entityId);
            this.processFormSubmissionStatus();
          }, statusPollInterval);
        }
        return;
      }

      // In all other cases, we're not waiting for any further response, so
      // we can reset the poll count.
      this.isSubmitting = false;
      this.isForceSubmit = false;
      this.resetRemainingPollAttempts();

      // If submission was successful, navigate to the sale case overview.
      if (this.s_submissionStatus === 'submitted') {
        this.completeFormSubmission();
      }
    },
    completeFormSubmission() {
      this.$gtag.event('Completed Milestone', {
        event_category: 'Task',
        event_label: 'Completed Sale Questionnaire',
      });
      this.setEventToHandle('completed_questionnaire');
      this.navigate('ViewEntity', {
        entityType: this.entityType,
        entityId: this.entityId,
      });
      this.disabled = false;
    },
    scrollToTop() {
      if (this.$router.currentRoute.name === 'ViewEntity') {
        this.$scrollTo('.page-header');
      }
    },
    allowForceRerender(value) {
      this.avoidForceRerender = value;
    },
    personAddEditMode(val) {
      // Switch off wizard next button when user is in Edit or Add person mode
      this.hideWizardNextButton = val;
    },
    isSortLegal() {
      return this.s_supplier === 'Sort Legal';
    },
    amendPersonsOnTitle() {
      this.amendingPersonsOnTitle = true;
      this.disabled = false;

      // Identify which tab to switch to so that user can amend persons on title, i.e.:
      // - 'About Your Sale' if the client has indicated that the clients are not the persons on title; or
      // - 'About You' otherwise.
      const newIndex = this.hasAlternativePersonsOnTitle ? aboutYourSaleIndex : aboutYouTabIndex;

      // Switch form wizard to appropriate tab for amending the persons on title
      const formWizardRef = this.$refs.saleCQWizard;
      const oldIndex = formWizardRef.activeTabIndex;
      formWizardRef.changeTab(oldIndex, newIndex);
    },
    resetRemainingPollAttempts() {
      this.pollAttemptNo = 0;
    },
  },
  watch: {
    loading: function () {
      if (!this.avoidForceRerender) {
        this.forceRerender();
      }
    },
    pollSubmissionStatus: function (newValue) {
      // We use a watch to ensure that polling is initiated even
      // if the user navigates back to the questionnaire.
      if (newValue) {
        this.processFormSubmissionStatus();
      } else {
        this.resetRemainingPollAttempts();
      }
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      debtsLoaded: (state) => state.sale.loaded,
      s_supplier: (state) => state?.sale?.config?.supplier,
      s_submissionStatus: (state) => state.sale.submissionStatus,
      s_matchDetails: (state) => state.sale.clientMatchDetails,
      s_titleDocumentId: (state) => state.sale.hmlrTitleDocumentId,
      s_titleDeedAlternatives: (state) => state.sale.titleDeedAlternatives,
    }),
    displayPendingDialog: {
      get() {
        return (
          this.s_submissionStatus === AWAITING_RESPONSE_HMLR ||
          this.s_submissionStatus === AWAITING_RESPONSE_DOCUMENT_ANALYSIS
        );
      },
    },
    displayClientNameMismatchDialog: {
      get() {
        return this.s_submissionStatus === NAME_MISMATCH && !this.isForceSubmit && !this.amendingPersonsOnTitle;
      },
    },
    pollSubmissionStatus: {
      get() {
        // Has the user submitted but we're still awaiting a first response from the API?
        const submissionCommenced =
          this.isSubmitting &&
          (this.s_submissionStatus === SUBMISSION_NOT_INITIATED || this.s_submissionStatus === READY_TO_SUBMIT);
        // Or, alternatively, are we awaiting a response from HMLR/document analysis?
        return submissionCommenced || this.displayPendingDialog;
      },
    },
    pollTimedOut: {
      get() {
        return this.pollAttemptNo > maxPollAttempts;
      },
    },
    hasAlternativePersonsOnTitle: {
      get() {
        return this.s_titleDeedAlternatives && this.s_titleDeedAlternatives.length > 0;
      },
    },
    amendPersonsOnTitleText: {
      get() {
        return this.hasAlternativePersonsOnTitle ? 'Amend persons on title' : 'Amend clients';
      },
    },
    showSaveProgressModal: {
      get() {
        // Only show the 'save progress' modal if user hasn't initiated the form submission process
        return !(this.displayPendingDialog || this.displayClientNameMismatchDialog || this.amendingPersonsOnTitle);
      },
    },
    // The form tab to start on
    startIndex: {
      // We should start on the first tab unless the user has initiated the form submission process
      get() {
        const submitAttempted = this.displayPendingDialog || this.displayClientNameMismatchDialog;
        let reviewPageTabIndex = defaultReviewPageTabIndex;

        // If Sort Legal, an extra tab is added
        if (this.isSortLegal()) {
          reviewPageTabIndex += 1;
        }
        return submitAttempted ? reviewPageTabIndex : aboutYouTabIndex;
      },
    },
  },
};
</script>

<template>
  <form-wrapper :entityType="entityType" :entityId="entityId" class="mb-5">
    <div class="home" slot="form-content">
      <div :key="clientQuestionnaireForm">
        <form-wizard
          ref="saleCQWizard"
          title=""
          subtitle=""
          :start-index="startIndex"
          :color="$t('site.formColour')"
          errorColor="#F44336"
          @on-loading="setLoading"
          @on-change="scrollToTop"
          stepSize="xs"
        >
          <tab-content title="About You" :before-change="validatePerson">
            <about-you @personAddEditMode="personAddEditMode" ref="aboutYou"></about-you>
            <save-progress-info v-if="showSaveProgressModal" />
          </tab-content>

          <tab-content title="About Your Sale" :before-change="saveSales">
            <about-your-sale ref="aboutYourSale"></about-your-sale>
          </tab-content>

          <tab-content title="Your Estate Agent" :before-change="saveEstateAgent">
            <your-estate-agent ref="yourEstateAgent"></your-estate-agent>
          </tab-content>

          <tab-content title="Related Purchase or Remortgage" :before-change="saveRelatedCases">
            <related-purchase-or-remortgage ref="relatedCases"></related-purchase-or-remortgage>
          </tab-content>

          <tab-content title="Occupiers" :before-change="validateOccupiers">
            <occupiers-sale-questionnaire ref="occupiersSaleQuestionnaire"></occupiers-sale-questionnaire>
          </tab-content>

          <tab-content title="Mortgages or Charges" :before-change="saveDebts">
            <mortgages-or-charges ref="debts"></mortgages-or-charges>
          </tab-content>

          <tab-content v-if="this.isSortLegal()" title="Additional Information" :before-change="validateOther">
            <other ref="other"></other>
          </tab-content>

          <tab-content title="Review" :before-change="validateReviewConfirmation">
            <sale-review-page ref="reviewPage"></sale-review-page>
          </tab-content>

          <tab-content title="Summary">
            <summary-page
              ref="summary"
              :disabled="disabled"
              :entityType="entityType"
              @preview="showPdfPreview"
              @download="showPdfPreview(true)"
              @save="saveAndRedirect"
              @submit="saveAndShowModalBeforeSubmit"
            ></summary-page>
          </tab-content>

          <!-- Customised Buttons -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left">
              <wizard-button
                v-if="props.activeTabIndex > 0"
                class="w-100 w-sm-auto bg-light border-dashed text-primary"
                @click.native="props.prevTab()"
                :style="props.fillButtonStyle"
              >
                <i class="icon-arrow-left5"></i> Previous
              </wizard-button>
            </div>
            <div class="wizard-footer-right">
              <div v-if="!props.isLastStep">
                <div v-if="!hideWizardNextButton">
                  <wizard-button
                    @click.native="props.nextTab()"
                    class="wizard-footer-right w-100 w-sm-auto"
                    :style="props.fillButtonStyle"
                    :disabled="wizardLoading"
                  >
                    Save and Continue
                    <i v-if="wizardLoading" class="icon-spinner11 spinner"></i>
                    <i v-else class="icon-arrow-right5"></i>
                  </wizard-button>
                </div>
              </div>
              <div v-if="displayPendingDialog">
                <hmlr-pending :status="s_submissionStatus" :timed-out="pollTimedOut"></hmlr-pending>
              </div>
              <div v-if="displayClientNameMismatchDialog">
                <hmlr-client-name-mismatch
                  :name-matches="s_matchDetails"
                  :title-document-id="s_titleDocumentId"
                  :amendPersonsOnTitleText="amendPersonsOnTitleText"
                  @amendPersonsOnTitle="amendPersonsOnTitle"
                  @submit="trySubmitForm"
                >
                </hmlr-client-name-mismatch>
              </div>
              <div v-else>
                <pdf-preview
                  form-name="SaleCQ"
                  ref="pdfPreview"
                  :entityType="entityType"
                  :entityId="entityId"
                  @allowForceRerender="allowForceRerender"
                ></pdf-preview>
                <modal
                  v-if="showSubmitModal"
                  closeButtonText="Close"
                  proceedButtonText="Save And Submit"
                  @proceed="trySubmitForm"
                  >Be aware that once you "Save And Submit", the form will be locked for any changes. Do you want submit
                  this form?
                </modal>
              </div>
            </div>
          </template>
        </form-wizard>
      </div>
    </div>
  </form-wrapper>
</template>

<style lang="scss" />
