<script>
import { mapMutations, mapState } from 'vuex';
import * as saleMutations from '@/store/sale/mutations';
import * as buildingMutations from '@/store/building/mutations';
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';
import QuickEditPerson from '@/components/forms/saleClientQuestionnaire/QuickEditPersonQuestionnaire';
import patchSale from '@/api/sales/patchSale';
import patchBuilding from '@/api/buildings/patchBuilding';
import Loader from '@/components/Loader';
import Hmlr from '@/api/hmlr/Hmlr';
import TitleRequest from '@/api/hmlr/title/TitleRequest';
import TitleResult, { notSure } from '@/api/hmlr/title/TitleResult';

export default {
  name: 'ReviewPage',
  components: { AddressLookup, QuickEditPerson, Loader },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
      editingAddress: false,
      saving: false,
      titleResults: new TitleResult([]),
      titleResultsLoading: false,
    };
  },
  computed: {
    ...mapState({
      s_clientIds: (state) => state.sale.clientIds,
      s_persons: (state) => state.persons.clients,
      s_clientDetailsUpdated: (state) => state.persons.clientDetailsUpdated,
      s_saleId: (state) => state.sale.saleId,
      s_buildingId: (state) => state.buildingId,
      s_saleAddress: (state) => state.building.address,
      s_saleAddressUpdated: (state) => state.sale.saleAddressUpdated,
      s_titleNumbers: (state) => state.sale.titleNumbers,
    }),
    clientDetailsUpdated: {
      get() {
        return this.s_clientDetailsUpdated;
      },
    },
    saleAddressUpdated: {
      get() {
        return this.s_saleAddressUpdated;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SALE_ADDRESS_UPDATED}`](value);
      },
    },
    saleAddress: {
      get() {
        return this.s_saleAddress;
      },
      set(value) {
        this[`${buildingMutations.BUILDING_PREFIX}${buildingMutations.BUILDING_SET_ADDRESS}`](value);
      },
    },
    titleNumbers: {
      get() {
        return this.s_titleNumbers;
      },
      set(value) {
        this[`${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_TITLE_NUMBERS}`](value);
      },
    },
    getPersons: {
      get() {
        return this.s_persons;
      },
    },
  },
  methods: {
    ...mapMutations([
      `${buildingMutations.BUILDING_PREFIX}${buildingMutations.BUILDING_SET_ADDRESS}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SALE_ADDRESS_UPDATED}`,
      `${saleMutations.SALE_PREFIX}${saleMutations.SALE_SET_TITLE_NUMBERS}`,
    ]),
    toggleEditAddress(value) {
      this.editingAddress = value;
    },
    async gotFullAddressInfo(address) {
      await this.fetchTitleNumbers(TitleRequest.fromSearchResult(this.s_saleId, address));
    },
    async fetchTitleNumbers(titleRequest) {
      this.titleResultsLoading = true;
      const titleResult = await Hmlr.findTitles(titleRequest);
      this.titleResults = titleResult.withPurchaseType(this.purchaseType);
      if (this.titleResults.count() === 1) {
        this.titleNumbers = this.titleResults.titles[0].TitleNumber;
      }
      this.titleResultsLoading = false;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async validateConfirmation() {
      return await this.$refs.checkboxObserver.validate();
    },
    async updateAddress() {
      const isValid = await this.$refs.saleAddressObserver.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchSale({
        buildingId: this.s_buildingId,
        id: this.s_saleId,
        titleNumbers: (this.s_titleNumbers ?? '').replace(notSure, ''),
      });

      await patchBuilding({
        id: this.s_buildingId,
        address: this.s_saleAddress,
      });

      this.saleAddressUpdated = true;

      this.editingAddress = false;
      this.saving = false;

      return true;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col my-2">
      <h2 class="col-md-12">Please check the following details are correct before submitting</h2>

      <div class="col-md-12">
        <div class="section-note">
          <b>
            These details will be used on your legal documents. Please verify the Property address and ensure that ALL
            persons registered on the Property Title are present with FULL NAMES (including middle names) as they would
            appear on your Passport or Driving Licence:
          </b>
        </div>
      </div>

      <div class="border-top my-3"></div>

      <!-- View/Edit Persons -->
      <div :key="clientDetailsUpdated" class="col-md-12">
        <div v-for="(item, index) in getPersons" :key="index">
          <h1 class="my-2">Person {{ index + 1 }} {{ item.firstName }} Details:</h1>
          <quick-edit-person :personIndex="index" :value="item"></quick-edit-person>
        </div>
      </div>
      <!-- View/Edit Persons End -->

      <div class="border-top my-3"></div>

      <!-- View/Edit Sale Address -->
      <loader v-if="saving">Saving Sale Address...</loader>
      <div v-else>
        <ValidationObserver ref="saleAddressObserver">
          <h1 class="col-md-12 my-2">Sale Property Address:</h1>

          <div class="col-md-12">
            <address-lookup
              :key="this.saleAddressUpdated"
              v-model="saleAddress"
              :addResidency="false"
              :displayOnly="!editingAddress"
              :requiresValidation="editingAddress"
              @gotFullAddressInfo="gotFullAddressInfo"
            ></address-lookup>

            <!-- Titles results -->
            <div class="row pb-2">
              <div class="col-md-3">
                <h5>
                  <label> Title Number(s) if known: </label>
                </h5>
              </div>

              <div v-if="titleResults.count() === 0 && titleResultsLoading === false" class="col-md-9">
                <ValidationProvider v-slot="validationContext" name="title numbers">
                  <b-form-input
                    id="title-numbers"
                    v-model="titleNumbers"
                    :disabled="!editingAddress"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                  ></b-form-input>
                  <b-form-invalid-feedback data-error-name="title-numbers-error">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </div>
              <div v-else-if="titleResultsLoading === false" class="col-md-9">
                <ValidationProvider v-slot="validationContext" :rules="{ required: false }" name="title numbers">
                  <b-form-select
                    id="title-numbers-select"
                    v-model="titleNumbers"
                    :value="titleNumbers"
                    :disabled="!editingAddress"
                    :options="titleResults.toOptions()"
                    :state="getValidationState(validationContext)"
                    aria-describedby="title-numbers-error"
                    class="form-control bg-white"
                  ></b-form-select>
                  <b-form-invalid-feedback data-error-name="title-numbers-error">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </div>
              <div v-else class="col-md-9">
                <loader></loader>
              </div>
            </div>
            <!-- Titles results end -->

            <div v-if="!editingAddress" class="row pb-2">
              <div class="col-lg-10"></div>

              <div class="col col-lg-2">
                <button class="btn bg-primary btn-edit btn-block" @click="toggleEditAddress(true)"></button>
              </div>
            </div>

            <div v-else class="row pb-2">
              <div class="col-lg-8"></div>

              <div class="col col-lg-2">
                <button class="btn btn-save btn-block" @click="updateAddress"></button>
              </div>

              <div class="col col-lg-2">
                <button class="btn btn-cancel btn-block" @click="toggleEditAddress(false)"></button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <!-- View/Edit Sale Address End -->

      <div class="border-top my-3"></div>

      <!-- Checkbox Confirmation -->
      <div>
        <div class="row no-gutters">
          <div class="form-group form-check my-1">
            <div class="col-md-12">
              <ValidationObserver ref="checkboxObserver">
                <ValidationProvider v-slot="{ errors }" name="confirmation" rules="confirmation">
                  <span class="form-text text-danger">{{ errors[0] }}</span>
                  <b-form-checkbox id="confirmation" v-model="checked" class="form-check-input" type="checkbox">
                    <h5>
                      I confirm that <strong>all person(s) details</strong> listed above and
                      <strong>sale property address</strong> are correct.
                    </h5>
                  </b-form-checkbox>
                </ValidationProvider>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
      <!-- Checkbox Confirmation End-->
    </div>
  </div>
</template>

<style scoped>
/deep/ .form-control:disabled {
  color: black !important;
}
</style>
