<script>
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';

const caseDefaultData = {
  related_property_address: {
    organisation_name: '',
    premise: '',
    thoroughfare: '',
    dependent_locality: '',
    locality: '',
    subAdministrativeArea: '',
    administrative_area: '',
    postal_code: '',
  },
  related_case_firm_id: '',
  related_case_type: '',
};

export default {
  name: 'RelatedCase',
  components: { AddressLookup },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      saving: false,
      relatedCase: {
        ...caseDefaultData,
        ...this.value,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }

      if (this.value) {
        this.$emit('save-existing-related-case', this.relatedCase);
      } else {
        this.$emit('save-new-related-case', this.relatedCase);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <ValidationObserver ref="observer" slim>
    <div class="row pb-1">
      <div class="col-md-3">
        <h5 class="line-height-base">
          <label class="mandatory">Type Of Case:</label>
        </h5>
      </div>

      <div class="col-md-9">
        <validation-provider v-slot="validationContext" :rules="{ required: true }" name="related case type">
          <b-form-group label-for="type" label-size="lg">
            <b-form-select
              id="relatedCaseType"
              v-model="relatedCase.related_case_type"
              :options="[{ value: '', text: 'Please choose', disabled: true }, 'purchase', 'remortgage']"
              :state="getValidationState(validationContext)"
              class="form-control bg-white text-capitalize"
              ref="answer"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="related-case-type-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>
    </div>

    <div class="row pb-1">
      <div class="col-md-3">
        <h5>
          <label> Related Property Address: </label>
        </h5>
      </div>
    </div>

    <address-lookup
      id="addressLockup"
      v-model="relatedCase.related_property_address"
      :addResidency="false"
    ></address-lookup>

    <div class="row pb-1">
      <div class="col-md-3">
        <h5 class="line-height-base">
          <label> Who is acting on your behalf (if known)? </label>
        </h5>
      </div>

      <div class="col-md-9">
        <validation-provider v-slot="validationContext" :rules="{ required: false }" name="related case firm" slim>
          <b-form-group label-for="lender" label-size="lg">
            <b-form-input
              id="relatedCaseFirm"
              v-model="relatedCase.related_case_firm_id"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="related-case-firm-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>
    </div>

    <div class="row pb-1 justify-content-end">
      <div class="col-md-3">
        <button class="btn btn-cancel btn-block mb-1" @click="cancel" />
      </div>
      <div class="col-md-3">
        <button id="save_related_case" class="btn btn-save btn-block" @click="save" />
      </div>
    </div>
  </ValidationObserver>
</template>
