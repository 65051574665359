<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/sale/mutations';
import Debt from '@/components/forms/saleClientQuestionnaire/mortgagesOrCharges/Debt';
import DebtsList from '@/components/forms/saleClientQuestionnaire/mortgagesOrCharges/DebtList';
import patchSale from '@/api/sales/patchSale';

export default {
  name: 'MortgagesOrCharges',
  components: { DebtsList, Debt },
  computed: {
    ...mapState({
      s_sale: (state) => state.sale,
    }),
    mortgagesOrCharges: {
      get() {
        return this.s_sale.mortgagesOrCharges;
      },
      set(value) {
        this[`${mutations.SALE_PREFIX}${mutations.SALE_SET_MORTGAGES_OR_CHARGES}`](value);
      },
    },
  },
  data() {
    return {
      showForm: false,
      currentEditedDebt: null,
    };
  },
  methods: {
    ...mapMutations([
      `${mutations.SALE_PREFIX}${mutations.SALE_SET_MORTGAGES_OR_CHARGES}`,
      `${mutations.SALE_PREFIX}${mutations.SALE_ADD_MORTGAGES_OR_CHARGES}`,
    ]),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    showDebtForm() {
      this.showForm = true;
      this.currentEditedDebt = null;
    },
    showList() {
      this.showForm = false;
    },
    saveNewDebt(event) {
      this[`${mutations.SALE_PREFIX}${mutations.SALE_ADD_MORTGAGES_OR_CHARGES}`](event);
      this.showList();
    },
    editDebt(debtIndexToBeEdited) {
      this.currentEditedDebt = this.mortgagesOrCharges[debtIndexToBeEdited];

      this.showForm = true;
    },
    saveExistingDebt(debt) {
      const indexForDebtToBeUpdated = this.mortgagesOrCharges.indexOf(this.currentEditedDebt);

      this.mortgagesOrCharges[indexForDebtToBeUpdated] = debt;
      this.currentEditedDebt = debt;

      this.showList();
    },
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || this.showForm) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchSale({
        id: this.s_sale.saleId,
        mortgagesOrCharges: this.s_sale.mortgagesOrCharges,
      });
      this.saving = false;

      return true;
    },
  },
};
</script>

<template>
  <div>
    <h2 class="mt-2">Mortgages or Charges</h2>

    <div class="row pb-1">
      <div class="col-md-9">
        <h5>
          Are you Selling the property with a mortgage? If yes, please click the add button to provide more information:
        </h5>
      </div>

      <div class="col-md-3 d-flex justify-content-end">
        <button class="btn btn-add" @click="showDebtForm" v-if="!showForm"></button>
      </div>
    </div>

    <div class="border-top my-3"></div>
    <ValidationObserver tag="div" ref="observer">
      <debt
        v-if="showForm"
        @cancel="showList()"
        @save-new-debt="saveNewDebt"
        @save-existing-debt="saveExistingDebt"
        v-model="currentEditedDebt"
      >
      </debt>
      <debts-list
        v-else-if="!showForm && mortgagesOrCharges.length !== 0"
        v-model="mortgagesOrCharges"
        @debt-edit="editDebt"
      >
      </debts-list>
      <p v-else class="text-muted">No mortgages or charges have been added.</p>
    </ValidationObserver>

    <div class="border-top my-3"></div>
  </div>
</template>
