<script>
export default {
  name: 'TitleDeedAlternatives',
  props: {
    value: {
      type: Object,
      default: () => ({
        first_name: null,
        middle_name: null,
        last_name: null,
      }),
    },
  },
  data() {
    return {
      saving: false,
      titleDeedAlternatives: {
        ...this.value,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }

      if (this.value) {
        this.$emit('save-existing-title-deed-alternatives', this.titleDeedAlternatives);
      } else {
        this.$emit('save-new-title-deed-alternatives', this.titleDeedAlternatives);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <ValidationObserver ref="observer" slim>
    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> First Name: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="first name" rules="required|alpha_spaces" v-slot="validationContext">
          <b-form-input
            :state="getValidationState(validationContext)"
            class="form-control"
            id="first-name"
            v-model="titleDeedAlternatives.first_name"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="first-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> Middle Name(s): </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="middle name" rules="alpha_spaces" v-slot="validationContext">
          <b-form-input
            :state="getValidationState(validationContext)"
            class="form-control"
            id="middle-name"
            v-model="titleDeedAlternatives.middle_name"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="middle-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Last Name: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="last name" rules="required|alpha_spaces" v-slot="validationContext">
          <b-form-input
            :state="getValidationState(validationContext)"
            class="form-control"
            id="last-name"
            v-model="titleDeedAlternatives.last_name"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="last-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-1 justify-content-end">
      <div class="col-md-3">
        <button class="btn btn-cancel btn-block mb-1" @click="cancel" />
      </div>
      <div class="col-md-3">
        <button class="btn btn-save btn-block" @click="save" />
      </div>
    </div>
  </ValidationObserver>
</template>
