<script>
import Modal from '@/components/Modal';
import {
  NAME_DISCREPANCY_DEED_POLL,
  NAME_DISCREPANCY_DIVORCE,
  NAME_DISCREPANCY_HMLR_ERROR,
  NAME_DISCREPANCY_MARRIED_OR_PARTNERSHIP,
  NAME_DISCREPANCY_PARTNERSHIP_DISSOLVED,
  NAME_DISCREPANCY_OTHER,
} from '@/values/nameDiscrepancyReasons';

const nameDiscrepancyOptions = [
  { value: null, text: 'Please select a reason', disabled: true },
  { value: NAME_DISCREPANCY_MARRIED_OR_PARTNERSHIP, text: 'I have married/entered a civil partnership.' },
  { value: NAME_DISCREPANCY_DEED_POLL, text: 'I have changed my name by deed poll.' },
  { value: NAME_DISCREPANCY_HMLR_ERROR, text: 'My name appears to be wrong at HM Land Registry.' },
  { value: NAME_DISCREPANCY_DIVORCE, text: 'I have divorced and changed back to my previous name.' },
  {
    value: NAME_DISCREPANCY_PARTNERSHIP_DISSOLVED,
    text: 'I have dissolved my civil partnership and changed back to my previous name.',
  },
  { value: NAME_DISCREPANCY_OTHER, text: 'Other (please give details)' },
];

export default {
  name: 'HmlrClientNameMismatchSubmit',
  components: { Modal },
  data() {
    return {
      additionalInfo: '',
      nameDiscrepancyOptions,
      reason: null,
    };
  },
  methods: {
    async handleProceed(value) {
      if (value) {
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          this.$emit('submit', {
            reason: this.reason,
            additionalInfo: this.additionalInfo,
          });
        }
      } else {
        this.$emit('back');
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  created() {
    // Make the 'NAME_DISCREPANCY_OTHER' constant available in the Vue template.
    // See https://stackoverflow.com/questions/42662144/how-could-i-use-const-in-vue-template#58900694
    this.NAME_DISCREPANCY_OTHER = NAME_DISCREPANCY_OTHER;
  },
};
</script>

<template>
  <modal
    closeButtonText="Back"
    proceedButtonText="Submit"
    :showHeader="false"
    :showButtons="true"
    @proceed="handleProceed"
  >
    <h2 class="text-center mb-3">Submission Confirmation</h2>
    <p>
      You have chosen to proceed with your case even though there is a discrepancy. You understand that if there is no
      valid reason for the name discrepancy there could be delays in completing your case.
    </p>
    <p>Please confirm why there is a name discrepancy by providing additional information below.</p>
    <ValidationObserver ref="observer">
      <div class="pb-3">
        <validation-provider name="reason" rules="required" v-slot="validationContext">
          <b-form-select
            v-model="reason"
            :options="nameDiscrepancyOptions"
            id="reason"
            class="form-control bg-white"
            :state="getValidationState(validationContext)"
          ></b-form-select>
          <b-form-invalid-feedback data-error-name="reason-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </div>
      <div class="">
        <validation-provider
          name="additional information"
          :rules="{ required_if: { target: 'reason', values: [NAME_DISCREPANCY_OTHER] } }"
          v-slot="validationContext"
          :skipIfEmpty="false"
        >
          <b-form-textarea
            name="additionalInfoTextArea"
            v-model="additionalInfo"
            id="additionalInfoTextArea"
            placeholder="Please enter any additional information here"
            rows="4"
            :state="getValidationState(validationContext)"
          ></b-form-textarea>
          <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="additional-info-textarea-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </div>
    </ValidationObserver>
  </modal>
</template>
