import getDocumentById from '@/api/documentManager/getDocumentById';
import axios from 'axios';

const previewDocument = async (file) => {
  const { data: uri } = await getDocumentById(file.id);
  const client = axios.create();
  const response = await client.get(uri, { responseType: 'blob' });
  // Put data in a blob
  const blob = new Blob([response.data], { type: response.headers['content-type'] });
  // Create a link in the DOM
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = file?.document_name ?? file?.documentName;
  // Simulate clicking said link
  link.click();
  URL.revokeObjectURL(link.href);
};
export default previewDocument;
