<script>
import { mapState } from 'vuex';
import pdf from 'vue-pdf';
import loader from '@/components/Loader';
import getPdf from '@/api/pdf/getPdf';

export default {
  name: 'SalePDFPreview',
  components: { pdf, loader },
  props: {
    formName: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      default: null,
    },
    entityType: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({
      s_reference: (state) => state.sale.sortReferId,
    }),
  },
  methods: {
    forceFileDownload(formType = 'form') {
      const url = window.URL.createObjectURL(new Blob([new Uint8Array(this.src)]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${formType}-${new Date().toISOString()}.pdf`);
      document.body.appendChild(link);
      link.click();
    },
    showModal() {
      this.showModalStatus = !this.showModalStatus;
      if (!this.showModalStatus) {
        this.$emit('allowForceRerender', false);
      }
    },
    incrementPage() {
      if (this.page < this.pageCount) {
        this.page++;
      }
    },
    decrementPage() {
      if (this.page > 1) {
        this.page--;
      }
    },
    async getPdf(downloadFile) {
      if (!this.isMobile() && !downloadFile) {
        this.showModal();
      }
      switch (this.formName) {
        case 'SaleCQ':
          this.src = await getPdf(this.s_reference, 'questionnaire');
          break;
        case 'TA6':
          this.src = await getPdf(this.s_reference, 'ta6');
          break;
        case 'TA7':
          this.src = await getPdf(this.s_reference, 'ta7');
          break;
        case 'TA10':
          this.src = await getPdf(this.s_reference, 'ta10');
          break;
      }
      if (this.isMobile() || downloadFile) {
        this.forceFileDownload();
      }
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  data: () => {
    return {
      showModalStatus: false,
      currentPage: 0,
      pageCount: 0,
      pdfToShow: '',
      page: 1,
      src: null,
      spinner: true,
      step: false,
    };
  },
};
</script>

<template>
  <div>
    <transition v-if="showModalStatus" name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header font-weight-bold">
              <h3>
                <slot name="header"> Page: {{ currentPage }} / {{ pageCount }}</slot>
              </h3>
            </div>

            <div class="modal-body">
              <loader v-if="spinner">Loading pdf preview...</loader>
              <div>
                <pdf
                  :page="page"
                  :src="src"
                  class="pdf"
                  @loaded="spinner = false"
                  @num-pages="pageCount = $event"
                  @page-loaded="currentPage = $event"
                ></pdf>
              </div>
            </div>

            <div class="modal-footer">
              <div class="footer">
                <div class="">
                  <button class="btn btn-info m-1" @click="decrementPage">Previous Page</button>
                  <button class="btn btn-info m-1" @click="incrementPage">Next Page</button>
                </div>
                <button class="btn btn-success" @click="this.showModal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.pdf {
  width: 700px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-footer {
  display: block;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  display: flex;
  justify-content: center;
}

.modal-body {
  margin: 20px 0;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
