<script>
export default {
  name: 'TitleDeedAlternativesList',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    edit(indexToEdit) {
      this.$emit('title-deed-alternatives-edit', indexToEdit);
    },
    remove(indexToRemove) {
      const titleDeedAlternatives = this.value.filter((_, index) => index !== indexToRemove);

      this.$emit('input', titleDeedAlternatives);
    },
    displayFullName: function () {
      return Array.from(arguments)
        .filter((item) => item)
        .join(' ');
    },
  },
};
</script>

<template>
  <div>
    <b-list-group :flush="true" class="p-0">
      <b-list-group-item v-for="(item, index) in value" :key="index">
        <div class="row">
          <div class="col-lg-7 inline-block text-lg-left text-center">
            <span class="align-middle">
              {{ displayFullName(item.first_name, item.middle_name, item.last_name) }}
            </span>
          </div>

          <div class="col-lg-5">
            <div class="col-lg-12 text-center text-lg-right">
              <button
                :id="'edit_title_deed_alternatives' + index"
                @click="edit(index)"
                class="btn btn-edit my-1 mx-2"
              ></button>

              <button
                :id="'remove_title_deed_alternatives' + index"
                @click="remove(index)"
                class="btn btn-remove my-1 mx-2"
              ></button>
            </div>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
