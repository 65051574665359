<script>
export default {
  name: 'DebtsList',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    edit(indexToEdit) {
      this.$emit('debt-edit', indexToEdit);
    },
    remove(indexToRemove) {
      const debts = this.value.filter((_, index) => index !== indexToRemove);

      this.$emit('input', debts);
    },
  },
};
</script>

<template>
  <div>
    <b-list-group :flush="true">
      <b-list-group-item v-for="(item, index) in value" :key="index">
        <div class="row">
          <div class="col-lg-7 text-lg-left text-center">
            <span>
              Lender: {{ item.existing_mortgage_lender }} Account number:{{ item.existing_mortgage_number }} Amount
              owing: {{ item.amount_owing }} Penalty amount: {{ item.penalty_amount }}</span
            >
          </div>
          <div class="col-lg-5">
            <div class="col-lg-12 text-center text-lg-right">
              <button :id="'edit_debt_' + index" @click="edit(index)" class="btn btn-edit my-1 mx-2" />
              <button :id="'remove_debt_' + index" @click="remove(index)" class="btn btn-remove my-1 mx-2" />
            </div>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
